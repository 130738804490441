import { render, staticRenderFns } from "./myPageContent.vue?vue&type=template&id=a1af747a&scoped=true"
import script from "./myPageContent.vue?vue&type=script&lang=js"
export * from "./myPageContent.vue?vue&type=script&lang=js"
import style0 from "./myPageContent.vue?vue&type=style&index=0&id=a1af747a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1af747a",
  null
  
)

export default component.exports