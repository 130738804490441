/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 20:49:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 17:05:58
 * @FilePath: /AIGo.work/src/router/index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import MainUserVue from "@/layout/mainUser.vue";
import PosterPage from "../views/invite/PosterPage.vue";
import store from "@/store";
import { stopMessage } from "@/service/SSEManager2";
import {
  STORAGE_PRELOGIN_PAGE,
  IS_ANONYMOUS_KEY,
  gzh_Qrcode_key,
} from "@/service/constant";
import {
  getOtherOrgId,
  getOtherRouterParam,
  getQueryStrByUrl,
  getQueryString,
  isAnonymous,
  cancalAnonymous
} from "@/utils";
import { getToken } from "@/service/login";
import { wxShareDefault } from "@/service/wxShare";
import { getVueObject } from "@/main";
import axios from "axios";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    name: "", // 客户端
    type: "user",
    component: MainUserVue,
    children: [
      {
        //首页
        meta: {
          icon: "el-icon-s-home",
          title: "Aigo.work --简单高效的职场AI助手，独家提示词优化功能。",
          index: 1,
        },
        path: "/",
        name: "newHome",
        component: () => import("../views/newHome/newHome.vue"),
      },
    //   {
    //     name: "首页-已登录",
    //     meta: {
    //       icon: "el-icon-s-home",
    //       title: "Aigo.work --简单高效的职场AI助手，独家提示词优化功能。",
    //       index: 1,
    //     },
    //     path: "/newHomeLogin",
    //     name: "newHomeLogin",
    //     component: () => import("../views/newHome/newHomeLogin.vue"),
    //   },
      {
        //首页-已登录
        meta: {
          icon: "el-icon-s-home",
          title: "Aigo.work --简单高效的职场AI助手，独家提示词优化功能。",
          index: 1,
        },
        path: "/home",
        name: "home",
        component: () => import("../views/newHome/newHomeLogin.vue"),
      },
      {
        //AI对话
        meta: {
          icon: "el-icon-s-home",
          title: "AI对话",
          index: 1,
        },
        path: "/home/:appId",
        name: "homeApp",
        component: () => import("../views/home/index.vue"),
      },
      {
        //AI画图
        meta: {
          icon: "el-icon-s-home",
          title: "AI画图",
          index: 2,
        },
        path: "/picture",
        name: "picture",
        component: () => import("../views/picture/index.vue"),
      },
      {
        meta: {
          icon: "el-icon-s-home",
          title: "Bing",
          index: 3,
        },
        path: "/bing",
        name: "bing",
        component: () => import("../views/bing/index.vue"),
      },
      {
        meta: {
          icon: "el-icon-s-home",
          title: "Stable Diffusion",
          index: 4,
        },
        path: "/sdpage",
        name: "sdpage",
        component: () => import("../views/sdPage/index.vue"),
      },
      {
        meta: {
          icon: "el-icon-s-home",
          title: "midjourney",
          index: 6,
        },
        path: "/midjourney",
        name: "midjourney",
        component: () => import("../views/mjPage/index.vue"),
      },
      {
        //产品列表
        meta: {
          icon: "el-icon-s-home",
          title: "产品列表",
          index: 7,
        },
        path: "/product",
        name: "product",
        component: () => import("../views/product/index.vue"),
      },
      {
        //产品列表
        meta: {
          icon: "el-icon-s-home",
          title: "产品列表",
          index: 7,
        },
        path: "/product123456789",
        name: "product123456789",
        component: () => import("../views/product/index2.vue"),
      },
      {
        //应用产品
        meta: {
          icon: "el-icon-s-home",
          title: "应用产品",
          index: 7,
        },
        path: "/appProduct",
        name: "appProduct",
        component: () => import("../views/appProduct/index.vue"),
      },
      {
        //消息
        meta: {
          icon: "el-icon-s-home",
          title: "消息",
        },
        path: "/notice",
        name: "notice",
        component: () => import("../views/notice/index.vue"),
      },
      {
        meta: {
          icon: "el-icon-s-home",
          title: "",
        },
        path: "/white",
        name: "white",
        component: () => import("../views/white/index.vue"),
      },
      {
        //邀请赚钱
        meta: {
          icon: "el-icon-s-home",
          title: "邀请赚钱",
          index: 7,
        },
        path: "/inviteIndex",
        name: "inviteIndex",
        component: () => import("../views/invite/inviteIndex.vue"),
      },
      {
        //创建应用
        meta: {
          icon: "el-icon-s-home",
          title: "创建应用",
          index: 7,
        },
        path: "/createApp/:appVersionId/:orgId?",
        name: "createApp",
        component: () => import("../views/app/createApp.vue"),
      },
    //   {
    //     //创建应用
    //     meta: {
    //       icon: "el-icon-s-home",
    //       title: "创建应用",
    //       index: 7,
    //     },
    //     path: "/createApp",
    //     name: "createApp",
    //     component: () => import("../views/app/createApp.vue"),
    //   },
      {
        //首页2
        meta: {
          icon: "el-icon-s-home",
          title: "首页2",
          index: 7,
        },
        path: "/newHome",
        name: "newHome2", 
        component: () => import("../views/newHome/newHome.vue"),
      },
      {
        meta: {
          icon: "el-icon-s-home",
          title: "app",
          index: 7,
        },
        path: "/appTalkDetail/:appId",
        name: "appTalkDetail",
        component: () => import("../views/app/appTalkDetail.vue"),
      },
      {
        meta: {
          icon: "el-icon-s-home",
          title: "appPreSet",
          index: 7,
        },
        path: "/appPreSet/:appId",
        name: "appPreSet",
        component: () => import("../views/app/appPreSet.vue"),
      },
      {
        meta: {
          icon: "el-icon-s-home",
          title: "",
          index: 7,
        },
        // path: '/app/:appId/:appVersionId',
        path: "/app/:tag/:appVersionId?/:type?/:other?", //type：不传是正式访问，1用户使用自己的应用 2预览  3正式访问
        //type是1的时候，appVersionId就是appVersionId， type是2的時候，appVersionId就是草稿id
        name: "app",
        component: () => import("../views/app/app.vue"),
      },
      {
        meta: {
          icon: "el-icon-s-home",
          title: "appDetail",
          index: 7,
        },
        path: "/appDetail",
        component: () => import("../views/app/appDetail/appDetail.vue"),
        children: [
          {
            //创建应用
            meta: {
              icon: "el-icon-s-home",
              title: "创建应用",
              index: 7,
              menu3Code: "createOrgApp",
            },
            path: "createApp/:appVersionId/:other?",
            name: "createApps",
            component: () => import("../views/app/createApp.vue"),
          },
          {
            //知识库
            meta: {
              icon: "el-icon-s-home",
              title: "知识库",
              index: 7,
              menu3Code: "orgknowledge",
            },
            path: "knowledge/:appVersionId/:other?",
            name: "knowledge",
            component: () => import("../views/app/knowledge/knowledge.vue"),
          },
          {
            //编辑知识库
            meta: {
              icon: "el-icon-s-home",
              title: "编辑知识库",
              index: 7,
              menu3Code: "orgknowledge",
            },
            path: "EditKnowledge/:appVersionId/:knowledgeId/:other?",
            name: "EditKnowledge1",
            component: () => import("../views/app/knowledge/EditKnowledge.vue"),
          },
          {
            meta: {
              icon: "el-icon-s-home",
              title: "编辑外部应用的知识库",
              index: 7,
              isAppDetail: true,
              sideMenuName: "orgAppList",
              menu3Code: "orgknowledge",
            },
            path: "EditKnowledgeApp/:appVersionId/:knowledgeId/:other?",
            name: "orgEditKnowledge1",

            isAppDetail: true,
            component: () => import("../views/app/knowledge/EditKnowledge.vue"),
          },
          {
            //提问记录
            meta: {
              icon: "el-icon-s-home",
              title: "提问记录",
              index: 7,
              menu3Code: "orgRecordManager",
            },
            path: "appQuestionRecordManager/:appVersionId",
            name: "appQuestionRecordManager1",
            component: () =>
              import(
                "../views/app/appQuestionRecord/appQuestionRecordManager.vue"
              ),
          },
        ],
      },
      {
        name: "orgAppDetail1",
        meta: {
          icon: "el-icon-s-home",
          title: "orgAppDetail",
          index: 7,
        },
        path: "/orgAppDetail",
        component: () => import("../views/app/appDetail/appDetail.vue"),
        children: [
          {
            meta: {
              icon: "el-icon-s-home",
              title: "智能体编辑",
              index: 7,
            },
            path: "createApp/:appVersionId/:other?",
            name: "orgCreateApp1",
            component: () => import("../views/app/createApp.vue"),
          },
          {
            meta: {
              icon: "el-icon-s-home",
              title: "知识库",
              index: 7,
            },
            path: "knowledge/:appVersionId/:other?",
            name: "orgKnowledge1",
            component: () => import("../views/app/knowledge/knowledge.vue"),
          },
          {
            meta: {
              icon: "el-icon-s-home",
              title: "知识库编辑",
              index: 7,
            },
            path: "EditKnowledge/:appVersionId/:knowledgeId/:other?",
            name: "orgEditKnowledge2",
            component: () => import("../views/app/knowledge/EditKnowledge.vue"),
          },
          {
            //提问记录
            meta: {
              icon: "el-icon-s-home",
              title: "提问记录",
              index: 7,
            },
            path: "appQuestionRecordManager/:appVersionId/:other?",
            name: "appQuestionRecordManager2",
            component: () =>
              import(
                "../views/app/appQuestionRecord/appQuestionRecordManager.vue"
              ),
          },
        ],
      },
      {
        //充值
        meta: {
          icon: "el-icon-s-home",
          title: "充值",
          index: 7,
        },
        path: "/RechargeWx",
        name: "RechargeWx",
        component: () => import("../views/recharge/RechargeWx.vue"),
      },
      {
        //包月
        meta: {
          icon: "el-icon-s-home",
          title: "包月",
          index: 7,
        },
        path: "/MonthlyWx",
        name: "MonthlyWx",
        component: () => import("../views/recharge/MonthlyWx.vue"),
      },
      {
        //星舰
        meta: {
          icon: "el-icon-s-home",
          title: "星舰",
          index: 7,
        },
        path: "/OrgannizationMain",
        name: "OrgannizationMain",
        component: () => import("../views/organization/OrgannizationMain.vue"),
      },
      {
        meta: {
          icon: "el-icon-s-home",
          title: "OrgannizationDetail",
          index: 7,
        },
        path: "/OrgannizationDetail",
        component: () =>
          import("../views/organization/OrgannizationDetail.vue"),
        children: [
          {
            name: "组织概览",
            meta: {
              icon: "el-icon-s-home",
              title: "组织概览",
              index: 7,
              sideMenuName: "OrgannizationInfo",
              loadPermission: true,
            },
            path: "OrgannizationInfo/:id?",
            name: "OrgannizationInfo",
            component: () =>
              import("../views/organization/OrgannizationInfo.vue"),
          },
          {
            name: "编辑组织",
            meta: {
              icon: "el-icon-s-home",
              title: "编辑组织",
              index: 7,
              sideMenuName: "EditOrganization",
              loadPermission: true,
            },
            path: "EditOrganization/:id?",
            name: "EditOrganization",

            component: () =>
              import("../views/organization/EditOrganization.vue"),
          },
          {
            name: "成员管理",
            meta: {
              icon: "el-icon-s-home",
              title: "成员管理",
              index: 7,
              sideMenuName: "orgManagerList",
              loadPermission: true,
            },
            path: "orgManagerList/:id?",
            name: "orgManagerList",

            component: () =>
              import("../views/organization/member/menberManager.vue"),
          },
          {
            name: "会员管理",
            meta: {
              icon: "el-icon-s-home",
              title: "会员管理",
              index: 7,
              sideMenuName: "menberManager",
              loadPermission: true,
            },
            path: "menberManager/:id?",
            name: "menberManager",

            component: () =>
              import("../views/organization/member2/menberManager.vue"),
          },
          {
            name: "分组权限",
            meta: {
              icon: "el-icon-s-home",
              title: "分组权限",
              index: 7,
              sideMenuName: "groupPermissions",
              loadPermission: true,
            },
            path: "groupPermissions/:id?",
            name: "groupPermissions",

            component: () =>
              import("../views/organization/groupPermissions.vue"),
          },
          {
            name: "私域群聊",
            meta: {
              icon: "el-icon-s-home",
              title: "私域群聊",
              index: 7,
              sideMenuName: "groupChat",
              loadPermission: true,
            },
            path: "groupChat/:id?",
            name: "groupChat",

            component: () =>
              import("../views/organization/groupChat.vue"),
          },
          {
            name: "分享奖励",
            meta: {
              icon: "el-icon-s-home",
              title: "分享奖励",
              index: 7,
              sideMenuName: "shareRewards",
              loadPermission: true,
            },
            path: "shareRewards/:id?",
            name: "shareRewards",

            component: () =>
              import("../views/organization/shareRewards.vue"),
          },
          {
            name: "AI智能体",
            meta: {
              icon: "el-icon-s-home",
              title: "AI智能体",
              index: 7,
              sideMenuName: "orgAppList",
              loadPermission: true,
            },
            path: "orgAppList/:id?",
            name: "orgAppList",

            component: () =>
              import("../views/organization/orgApp/orgAppList.vue"),
          },
          {
            name: "企业套餐",
            meta: {
              icon: "el-icon-s-home",
              title: "企业套餐",
              index: 7,
              sideMenuName: "payPage",
              loadPermission: true,
            },
            path: "payPage/:id",
            name: "payPage",

            component: () => import("../views/organization/pay/payPage.vue"),
          },
          {
            name: "组织会员费设置",
            meta: {
              icon: "el-icon-s-home",
              title: "组织会员费设置",
              index: 7,
              sideMenuName: "MoneySet",
              loadPermission: true,
            },
            path: "MoneySet/:id",
            name: "MoneySet",

            component: () =>
              import("../views/organization/price/orgPricePage.vue"),
          },
          {
            name: "套餐用量",
            meta: {
              icon: "el-icon-s-home",
              title: "套餐用量",
              index: 7,
              sideMenuName: "usageDetail",
              loadPermission: true,
            },
            path: "usageDetail/:id",
            name: "usageDetail",

            component: () =>
              import("../views/organization/usageDetail/usageDetail.vue"),
          },
          {
            name: "收益数据",
            meta: {
              icon: "el-icon-s-home",
              title: "收益数据",
              index: 7,
              sideMenuName: "orgIncom",
              loadPermission: true,
            },
            path: "orgIncom/:id",
            name: "orgIncom",

            component: () =>
              import("../views/organization/orgIncom/orgIncom.vue"),
          },
          {
            meta: {
              icon: "el-icon-s-home",
              title: "知识库训练",
              index: 7,
              sideMenuName: "konwledge",
              loadPermission: true,
              isAppDetail: true,
            },
            path: "globalKnowledge/:other",
            name: "globalKnowledge",
            sideMenuName: "konwledge",
            isAppDetail: true,
            component: () => import("../views/app/knowledge/knowledge.vue"),
          },
          {
            name: "编辑知识库",
            meta: {
              icon: "el-icon-s-home",
              title: "编辑知识库",
              loadPermission: true,
              sideMenuName: "konwledge",

              index: 7,
            },
            path: "EditKnowledge/:knowledgeId/:other?",
            name: "EditKnowledge2",
            sideMenuName: "konwledge",

            component: () => import("../views/app/knowledge/EditKnowledge.vue"),
          },
          {
            name: "优惠券",
            meta: {
              icon: "el-icon-s-home",
              title: "优惠券",
              loadPermission: true,
              sideMenuName: "Coupon",
              index: 7,
            },
            path: "CouponMain",
            name: "CouponMain",
            sideMenuName: "Coupon",
            component: () => import("../views/coupon/CouponMain.vue"),
            children:[
                {
                    name: "优惠券",
                    meta: {
                      icon: "el-icon-s-home",
                      title: "优惠券",
                      loadPermission: true,
                      sideMenuName: "Coupon",
                      index: 7,
                    },
                    path: "CouponList/:other?",
                    name: "CouponList",
                    sideMenuName: "Coupon",
                    component: () => import("../views/coupon/CouponList.vue"),
                },{
                    name: "兑换券",
                    meta: {
                      icon: "el-icon-s-home",
                      title: "兑换券",
                      loadPermission: true,
                      sideMenuName: "Coupon",
                      index: 7,
                    },
                    path: "FullCouponList/:other?",
                    name: "FullCouponList",
                    sideMenuName: "Coupon",
                    component: () => import("../views/coupon/FullCouponList.vue"),
                }
            ]
          },
          {
            name: "orgAppDetail2",
            meta: {
              icon: "el-icon-s-home",
              title: "orgAppDetail",
              index: 7,
              sideMenuName: "orgAppDetail",
              loadPermission: true,
            },
            path: "orgAppDetail",
            component: () => import("../views/app/appDetail/appDetail.vue"),
            children: [
              {
                meta: {
                  icon: "el-icon-s-home",
                  title: "创建AI智能体",
                  index: 7,
                  isAppDetail: true,
                  sideMenuName: "orgAppList",
                  loadPermission: true,
                  menu3Code: "createOrgApp",
                },
                path: "createApp/:appVersionId/:other?",
                name: "orgCreateApp2",

                component: () => import("../views/app/createApp.vue"),
              },
              {
                meta: {
                  icon: "el-icon-s-home",
                  title: "知识库",
                  index: 7,
                  isAppDetail: true,
                  sideMenuName: "orgAppList",
                  loadPermission: true,
                  menu3Code: "orgknowledge",
                },
                path: "knowledge/:appVersionId/:other?",
                name: "orgKnowledge2",
                sideMenuName: "orgAppList",
                isAppDetail: true,
                component: () => import("../views/app/knowledge/knowledge.vue"),
              },
              {
                meta: {
                  icon: "el-icon-s-home",
                  title: "知识库编辑",
                  index: 7,
                  isAppDetail: true,
                  sideMenuName: "orgAppList",
                  loadPermission: true,
                  menu3Code: "orgknowledge",
                },
                path: "EditKnowledgeOrgApp/:appVersionId/:knowledgeId/:other?",
                name: "orgEditKnowledge3",

                isAppDetail: true,
                component: () =>
                  import("../views/app/knowledge/EditKnowledge.vue"),
              },
              {
                name: "提问记录",
                meta: {
                  icon: "el-icon-s-home",
                  title: "提问记录",
                  index: 7,
                  isAppDetail: true,
                  sideMenuName: "orgAppList",
                  loadPermission: true,
                  menu3Code: "orgRecordManager",
                },
                path: "appQuestionRecordManager/:appVersionId/:other?",
                name: "appQuestionRecordManager3",

                isAppDetail: true,
                component: () =>
                  import(
                    "../views/app/appQuestionRecord/appQuestionRecordManager.vue"
                  ),
              },
            ],
          },
          // 工作流模块
          {
            name: "Bot",
            meta: {
              icon: "el-icon-s-home",
              title: "Bot",
              index: 7,
              sideMenuName: "Bot",
              loadPermission: true,
            },
            path: "bot/:other?",
            name: "bot",

            component: () =>
              import("../views/workFlow/bot.vue"),
          },
          {
            name: "插件",
            meta: {
              icon: "el-icon-s-home",
              title: "插件",
              index: 7,
              sideMenuName: "Plugin",
              loadPermission: true,
            },
            path: "Plugin",
            name: "Plugin",

            component: () =>
              import("../views/workFlow/plugin.vue"),
          },
          {
              meta: {
                  icon: "el-icon-s-home",
                  title: "插件工具",
                  index: 7,
                  sideMenuName: "Plugin",
                  loadPermission: true,
              },
              path: "pluginTool/:pluginVersionId?",
              name: "PluginTool",
              component: () => import("../views/workFlow/pluginTool.vue"),
          },
          {
            name: "工作流",
            meta: {
              icon: "el-icon-s-home",
              title: "工作流",
              index: 7,
              sideMenuName: "WorkFlow",
              loadPermission: true,
            },
            path: "WorkFlow/:other?",
            name: "WorkFlow",

            component: () =>
              import("../views/workFlow/workFlow.vue"),
          },
        ],
      },
      {
        //组织
        meta: {
          icon: "el-icon-s-home",
          title: "组织",
          index: 7,
        },
        path: "/getCode",
        name: "getCode",
        component: () => import("../views/home/getCode.vue"),
      },
      {
        //微信分享测试
        meta: {
          icon: "el-icon-s-home",
          title: "微信分享测试",
          index: 7,
        },
        path: "/wxShareTest",
        name: "wxShareTest",
        component: () => import("../views/home/wxShareTest.vue"),
      },
      {
        path: "/CreateOrganization",
        name: "CreateOrganization",
        type: "CreateOrganization",
        meta: {
          title: "创建组织",
        },
        component: () => import("../views/organization/CreateOrganization.vue"),
      },
      {
        path: "/WriteArcitlePc/:orgId/:articleId?",
        name: "WriteArcitlePc",
        type: "WriteArcitlePc",
        meta: {
          title: "写文章",
        },
        component: () => import("../views/forum/WriteArcitlePc.vue"),
      },
      {
        path: "/WriteArcitleMobil/:orgId/:articleId?",
        name: "WriteArcitleMobil",
        type: "WriteArcitleMobil",
        meta: {
          title: "写文章",
        },
        component: () => import("../views/forum/WriteArcitleMobil.vue"),
      },
        {
            path: "/ArticleDetail/:articleId",
            name: "ArticleDetail",
            type: "ArticleDetail",
            meta: {
                title: "",
            },
            component: () => import("../views/forum/ArticleDetail.vue"),
        },
        //  分享
        {
            meta: {
                title: "",
            },
            path: "/share",
            name: "share",
            component: () => import("../views/common/share.vue"),
        },
        //  无权限页面提示
        {
            meta: {
                title: "暂无权限",
            },
            path: "/noPermission",
            name: "noPermission",
            component: () => import("../views/common/noPermission.vue"),
        },
        // 信息沟通PC
        {
            // 提问对话
            meta: {
                icon: "el-icon-s-home",
                title: "提问对话",
                index: 7,
            },
            path: "/dialogPC",
            name: "dialogPC",
            component: () => import("../views/organization/message/dialogPC.vue"),
        },
         // 信息沟通Mobile
         {
            // 提问对话
            meta: {
                icon: "el-icon-s-home",
                title: "提问对话",
                index: 7,
            },
            path: "/dialogMobile",
            name: "dialogMobile",
            component: () => import("../views/organization/message/dialogMobile.vue"),
        },         
    ],
  },
  {
    path: "/poster",
    name: "poster",
    type: "poster",
    component: PosterPage,
  },
  {
    path: "/NoPermission",
    name: "NoPermission",
    type: "NoPermission",
    component: () => import("../layout/NoPermission.vue"),
  },
  {
    path: "/WxLoginPage",
    name: "WxLoginPage",
    type: "WxLoginPage",
    component: () => import("../layout/WxLoginPage.vue"),
  },
  // 隐私协议
  {
    meta: {
      icon: "el-icon-s-home",
      title: "隐私协议",
      index: 7,
    },
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/agreement/privacy.vue"),
  },
  {
    meta: {
      icon: "el-icon-s-home",
      title: "用户服务协议",
      index: 7,
    },
    path: "/userServices",
    name: "userServices",
    component: () => import("../views/agreement/userServices.vue"),
  },
  {
    meta: {
      icon: "el-icon-s-home",
      title: "舰长协议",
      index: 7,
    },
    path: "/captain",
    name: "captain",
    component: () => import("../views/agreement/captain.vue"),
  },
  {
    meta: {
      icon: "el-icon-s-home",
      title: "",
      index: 7,
    },
    path: "/appHome",
    name: "appHome",
    component: () => import("../views/appSide/appHome.vue"),
  },
//   {
//     // 组织主页
//     meta: {
//       icon: "el-icon-s-home",
//       title: "",
//       index: 7,
//     },
//     path: "/orgHome/:tag",
//     name: "orgHome",
//     component: () => import("../views/organization/orgHome.vue"),
//   },
  {
    // 组织主页
    meta: {
      icon: "el-icon-s-home",
      title: "",
      index: 7,
    },
    path: "/ss/:tag",
    name: "ss",
    component: () => import("../views/organization/orgHome.vue"),
  },
  {
    // 小窗口
    meta: {
      icon: "el-icon-s-home",
      title: "小窗口",
      index: 7,
    },
    path: "/miniWin/:tag",
    name: "miniWin",
    component: () => import("../views/miniWin/MiniWin.vue"),
  },
  {
    // 组织会员主页
    meta: {
      icon: "el-icon-s-home",
      title: "",
      index: 7,
      keepAlive:true
    },
    path: "/starship/:tag",
    name: "starship",
    component: () => import("../views/organization/memberHome/MenberHome.vue"),
  },
  {
    // 组织会员主页
    meta: {
      icon: "el-icon-s-home",
      title: "",
      index: 7,
    },
    path: "/MenberHome/:tag",
    name: "MenberHome",
    component: () =>
      import("../views/organization/memberHome/MenberHome404.vue"),
  },
  {
    meta: {
      icon: "el-icon-s-home",
      title: "",
      index: 7,
    },
    path: "/MenberVipPage/:tag", //组织id
    name: "MenberVipPage",
    component: () => import("../views/organization/menberVip/MenberVipPage"),
  },
  {
    meta: {
      icon: "el-icon-s-home",
      title: "",
      index: 7,
    },
    path: "/dragDemo/:id?",
    name: "dragDemo",
    component: () => import(/* webpackChunkName: "about" */ "@/views/workFlow/dragDemo"),
  },
  {
    meta: {
      icon: "el-icon-s-home",
      title: "",
      index: 7,
    },
    path: "/createdTool/:id?",
    name: "createdTool",
    component: () => import(/* webpackChunkName: "about" */ "@/views/workFlow/plugins/createdTool"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
        icon: "el-icon-s-home",
        title: "登录与注册",
        index: 7,
    },
    component: () => import("../views/login/index.vue"),
  }
];

const router = new VueRouter({
    mode: "history",
    // mode: "hash",
    base: "/",
    routes,
});
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach(async (to, from, next) => {
    // console.log("==路由守卫== to.path", to);
    isNeedReflesh();

    if (getVueObject()) {
        getVueObject().$bus.$emit("audioStop");
        getVueObject().$bus.$emit("clearAudioAutoPlay");
    }

    let baseUrl = localStorage.getItem('baseRouter')
    let isXmrc = false
    if(baseUrl){
        isXmrc = baseUrl.includes("ai.xmgxrc.com")
    }else{
        isXmrc = location.href.includes("ai.xmgxrc.com")
    }
    var userCode = getQueryString("userCode");
    //userCode是邀请码，保存在本地，注册或绑定的时候，如果本地有这个，就带上
    if (userCode) {
        localStorage.setItem("userCode", userCode);
    }
    if (from.name == 'starship'||from.name == 'OrgannizationMain'){
        localStorage.setItem("formPath", from.path);
    }else{
        localStorage.setItem("formPath", '');
    }
    if (to.name == "WxLoginPage") {
        next();
        return;
    }
    if (to.path.indexOf("OrgannizationDetail") > 0 || to.path.indexOf("OrgannizationMain") > 0 || to.path.indexOf("ArticleDetail") > 0 
    || to.path.indexOf("share") > 0 || to.path.indexOf("WriteArcitlePc") > 0 || to.path.indexOf("WriteArcitleMobil") > 0 || to.name == "app") {
        store.commit("SET_topInviteBtnShow", false);
        store.commit("SET_topVipBtnShow", false);
    } else {
        store.commit("SET_topInviteBtnShow", true);
        store.commit("SET_topVipBtnShow", true);
    }
    
    if (to.path.indexOf("createApp") < 0){
        store.commit('SET_showMenu', true)
    }

    if (from.name == "app") {
        store.commit("SET_curOpenAppOrgid", "0");
        // serverStop()
        stopMessage();
    }
    if (to.name != "app") {
        cancalAnonymous();
    }
    document.title = to.meta.title;
    //参数里有anonymous并且等于1，就保存isAnonymous为1
    var anonymousQuery = getQueryStrByUrl(location.href, "anonymous");

    if (anonymousQuery == 1) {
        if (!isAnonymous()) {
            localStorage.setItem(IS_ANONYMOUS_KEY, 1);
            //匿名登录
            await store.dispatch("anonymousLogin");
        }
    }

    if (!getToken()) {
        if (isAnonymous()) {
            await store.dispatch("anonymousLogin");
        } else {
            if (to.name == "app") {
                localStorage.setItem(IS_ANONYMOUS_KEY, 1);
                //匿名登录
                await store.dispatch("anonymousLogin");
            }
        }
    }

    //加载用户信息
    if (store.haveUserInfo) {
        await store.dispatch("loadHomeData");
    }

    if (to.meta.loadPermission) {
        var orgId;
        if (to.params.other) {
            var jsonObj = JSON.parse(decodeURIComponent(to.params.other));
            orgId = jsonObj["orgId"];
        }

        if (!orgId) {
            orgId = to.params.id;
        }
        await store.dispatch("loadMyPermission", { orgId: orgId });
        if (to.name != "app") {
            wxShareDefault(location.origin + to.path, to.meta.title);
        }
    }
    // 这些路由不需要登录
    if(to.path == '/share'){
        next()
        return
    }
    if(isXmrc){
        if (!getToken()){//如果未登录
            if (to.path == "/" || to.path == "/home" || to.path == "/index" || to.path == "/OrgannizationMain" || to.name == "app" || to.name == "ss" || to.name == '/appHome' || to.name == '/appProduct'){
                next("/login")
                return
            }else{
                next()
                return
            }
        }else{
            if (to.path == "/" || to.path == "/home" || to.path == "/index" || to.path == "/OrgannizationMain" || to.name == "ss" ){
                next('/starship/198909')
                return
            }else{
                next()
                return
            }
            // if(from.path == "/login"){
            //     next('/starship/198909')
            //     return
            // }else{
            //     next()
            //     return
            // }
        }
    }else{
        if (to.path == "/" || to.name == "ss" || to.name == "app" || to.path == "/login" || to.path == '/WxLoginPage' 
            || to.path == '/privacy' || to.path == '/userServices' || to.path == '/captain' || to.path == '/noPermission' 
            || to.path == '/appHome' || to.path == '/appProduct') {
            if (!getToken()){
                if(to.path != "/login" && to.path != '/WxLoginPage'){
                  localStorage.setItem(STORAGE_PRELOGIN_PAGE, to.path)
                }
                next()
                return
            }else{
                if (to.path == "/" || to.path == "/login" || to.path == '/WxLoginPage'){
                    next("/home")
                    return
                }else{
                    next()
                    return
                }
            }
        } else {
            if (!getToken()){//如果未登录
                localStorage.setItem(STORAGE_PRELOGIN_PAGE, to.path)
                next("/login")
                return
            }else{
                next()
                return
            }
        }
    }

  // if (to.path !== '/' && to.path !== '/newHomeLogin' && to.path !== '/home'){
  //     if (!getToken()) {//如果未登录，跳首页
  //         localStorage.setItem(STORAGE_PRELOGIN_PAGE, to.path)//跳首页之前，记录当前想去的页面
  //         var userCode = getQueryString("userCode")
  //         if(userCode){
  //             next({
  //                 path:'/',
  //                 query:{userCode}
  //             })
  //         }else{
  //             next('/')
  //         }
  //     }else{
  //         if(to.name != "app"){
  //             wxShareDefault(location.origin + to.path, to.meta.title)
  //         }
  //         next()
  //     }
  // }else{
  //     if(to.path == '/newHomeLogin'){
  //         if(getToken()){
  //             if(to.name != "app"){
  //                 wxShareDefault(location.origin + to.path, to.meta.title)
  //             }
  //             next()
  //         }else{
  //             next('/')
  //         }
  //     }else if(to.path == '/home'){
  //         if(getToken()){
  //             if(to.name != "app"){
  //                 wxShareDefault(location.origin + to.path, to.meta.title)
  //             }
  //             next()
  //         }else{
  //             next('/')
  //         }
  //     }else if(to.path == '/'){
  //         if(getToken()){
  //             next('/home')
  //         }else{
  //             if(to.name != "app"){
  //                 wxShareDefault(location.origin + to.path, to.meta.title)
  //             }
  //             next()
  //         }
  //     }
  // }
});

// router.beforeEach((to, from, next)=>{
//   if (to.path !== '/'){
//     let token = store.state.token
//     if (!token) {
//       next('/')
//     }else{
//       next()
//     }
//   }else{
//     next()
//   }
// })

router.afterEach((to, from) => {
    // 在路由切换之后执行的逻辑
    const link = document.querySelector('link[rel~="icon"]');
    if(to.name != "app" && to.name != "ss" && to.name != "starship" && to.path.indexOf("ArticleDetail") < 0){
        link.href = 'https://static.aigo.work/home/20230912-115637.png'
    }
});
function isNeedReflesh() {
//   var jsonUrl = `https://aigo.work/version.json`; 
//   if (process.env.NODE_ENV != "production") {
//     jsonUrl = location.origin + "/version.json";
//   }

//   var t = new Date().getTime();
//   var realUrl = `${jsonUrl}?t=${t}`;

//   axios
//     .get(realUrl)
//     .then((response) => {
//       // 处理返回的 JSON 数据
//       console.log("加载版本号文件成功", response.data);
//       console.log("本地版本号", window.aigoFileVersion);

//       if (response.data.v != window.aigoFileVersion) {
//         console.log("location.reload()");
//         getVueObject().$updateDialog.show({}, () => {});
//       } else {
//         // getVueObject().$updateDialog.show({}, ()=>{})
//       }
//     })
//     .catch((error) => {
//       // 错误处理
//       console.error("加载版本号出错", error);
//     });
}

export default router;