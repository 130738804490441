/*
export const baseUrl = 'https://gpt.v-wim.xyz-a-s'
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-04-25 15:58:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 10:55:29
 * @FilePath: /AIGo.work/src/api/api.js
 */

let baseUrl;
let baseRouter;
// console.log("process.env", process.env);
switch (process.env.NODE_ENV) {
    case "development":
        // baseUrl = 'https://6e8d-61-176-96-198.ngrok-free.app'
        // baseUrl = 'https://gptnew.v-wim.xyz'
        // baseUrl = 'http://192.168.2.17:8081'
        // baseUrl = "https://AIGo.work/api";
        // baseUrl = 'http://192.168.124.67:8083'// 开发本地测试
        // baseUrl = 'http://192.168.124.67:8081'
        // baseUrl = 'http://127.0.0.1:8083'//本地测试
        // baseUrl = 'http://39.99.245.79/api'
        baseUrl = "https://test.aigo.work/api";
        // baseUrl = "https://aigo.work/api"
        // baseUrl = "https://ai.xmgxrc.com/api"

        //项目路径配置
        baseRouter = "https://test.aigo.work/";
        // baseRouter = "https://ai.xmgxrc.com/"
        break;
    case "qa": //外网测试服务器
        // baseUrl = 'https://6e8d-61-176-96-198.ngrok-free.app'
        // baseUrl = 'https://gptnew.v-wim.xyz'
        // baseUrl = 'http://192.168.2.17:8081'
        // baseUrl = 'https://AIGo.work/api'
        // baseUrl = "http://192.168.124.67:8083"
        // baseUrl = 'http://192.168.124.67:8081'
        // baseUrl = 'http://127.0.0.1:8083'
        baseUrl = "https://test.aigo.work/api";
        // baseUrl = 'http://39.99.245.79/api'

        //项目路径配置
        baseRouter = "https://test.aigo.work/";
        break;

    case "qaserver":
        // baseUrl = 'https://6e8d-61-176-96-198.ngrok-free.app'
        // baseUrl = 'https://gptnew.v-wim.xyz'
        // baseUrl = 'http://192.168.2.17:8081'
        // baseUrl = 'https://AIGo.work/api'
        baseUrl = "http://127.0.0.1:8083";
        // baseUrl = 'http://192.168.124.67:8081'//测试

        //项目路径配置
        baseRouter = "https://127.0.0.1:8083/";
        break;

    case "production":
        // baseUrl = 'https://gptnew.v-wim.xyz'
        // baseUrl = 'http://192.168.2.17:8081'
        // baseUrl = 'http://192.168.124.67:8083'
        baseUrl = "https://aigo.work/api";

        //项目路径配置
        baseRouter = "https://aigo.work/";
        break;

    case "alone": //厦门人才项目服务器
        baseUrl = "https://ai.xmgxrc.com/api";

        //项目路径配置
        baseRouter = "https://ai.xmgxrc.com/";
        break;
    case "app": //app打包
        baseUrl = "https://xingjianai.com/api";
        // baseUrl = "https://test.aigo.work/api";

        //项目路径配置
        baseRouter = "https://xingjianai.com/";
        // baseRouter = "https://test.aigo.work/";
        break;
}

localStorage.setItem("baseRouter", baseRouter);

export const HTTP_URI = {
    BASEURL: baseUrl,
    GETSSE: baseUrl + "/sse/connect",
    CLOSESSE: baseUrl + "/sse/connect/close",
    GPTCHAT: baseUrl + "/gpt/chat",
    LINSHILOGIN: baseUrl + "/auth/temp",
    CHATTYPE: baseUrl + "/client/home",
    HOME_V2: baseUrl + "/client/homeV2",
    REDTYPE: baseUrl + "/client/register/method",
    ACCOUNT: baseUrl + "/auth/register",
    SENDCODE: baseUrl + "/send/email",
    LOGIN: baseUrl + "/auth/login",
    EMAILREGISTER: baseUrl + "/auth/register/email",
    DELCHAT: baseUrl + "/client/delete/log",
    CLEARCHAT: baseUrl + "/client/empty/log",
    NAVSHOWORHIDE: baseUrl + "/client/getFunctionState",
    CHANGEPWD: baseUrl + "/client/updatePassword",
    CHANGEAVATAR: baseUrl + "/client/updateAvatar",
    GPTPRUTICE: baseUrl + "/gpt/official",
    PRODUCT: baseUrl + "/client/recharge",
    YIPAY: baseUrl + "/order/yi/create",
    CARDPWDPAY: baseUrl + "/order/card/pin",
    BINGCHAT: baseUrl + "/bing/chat",
    SDMODEL: baseUrl + "/sd/getModel", //获取模型
    GETSAMPLERS: baseUrl + "/sd/getSamplers", //获取采样方法
    GETLORA: baseUrl + "/sd/getLora", //获取lora列表
    CHATSD: baseUrl + "/sd/create",
    CHATSDSTATUS: baseUrl + "/sd/getQueue",
    CLOSESS: baseUrl + "/sse/close/",
    ALIPAY_GOTOPAY: baseUrl + "/alipay/goToPay",

    GET_ORDER_STATUS: baseUrl + "/order/get-order-status",

    VIP_LIST: baseUrl + "/vip-package/selectByCondition",

    VIP_INFO: baseUrl + "/vip-package/get-user-vip-info",

    REQ_OPTIMIZE: baseUrl + "/gpt/req-optimize",

    GET_USERINFO: baseUrl + "/client/getUserInfo",

    GET_COMMISION_CONFIG:
        baseUrl + "/generalConfig/getInviterCommissionRatioConfig",

    GET_WITHDRAWAL_CONFIG:
        baseUrl + "/generalConfig/getInviterCommissionFeeOptionConfig",
    // 获取音色类型配置信息
    getVoiceTypeConfig: baseUrl + "/generalConfig/getVoiceTypeConfig",

    GET_MONEY_ACCOUNT: baseUrl + "/client/getMoneyAccount",

    SET_MONEY_ACCOUNT: baseUrl + "/client/addMoneyAccount",

    ADD_ORDER_WITHDRAW: baseUrl + "/event/addOrderWithdraw",

    GET_ORDER_EITHDRAWPAGE: baseUrl + "/event/getOrderWithdrawPage",

    GET_EVENT_PAGE: baseUrl + "/event/getEventPage",

    GET_LAST_MESSAGE: baseUrl + "/client/getLastMessages",

    GET_SEND_VCODE: baseUrl + "/auth/sendVCode",

    GET_TICKET: baseUrl + "/wx/getTicket",

    GET_LOGIN_BY_WX2: baseUrl + "/auth/loginByWx2",

    GET_WX_PAY_URL: baseUrl + "/wxpay/getWxPayUrl",

    GET_WX_PAY_JS_PARAM: baseUrl + "/wxpay/getWxPayJsParams",

    GET_BIND_PHONE_NUMBER: baseUrl + "/auth/bindPhoneNumber",

    GET_QUERY_MESSAGE_LOGS: baseUrl + "/client/queryMessageLogs",

    GET_MESSAGE_LOG_BY_ID: baseUrl + "/client/getMessageLogById",

    // 指定应用版本下架
    setAppVersionOffline: baseUrl + "/v2/client/app/setAppVersionOffline",

    GET_applyApp: baseUrl + "/v2/client/app/applyApp",

    GET_queryEnabledAppRoles: baseUrl + "/v2/client/appRole/queryEnabledAppRoles",

    GET_queryOnlineApps: baseUrl + "/v2/client/app/queryOnlineApps",

    GET_queryApps: baseUrl + "/v2/client/app/queryApps",

    CHAT_BY_STREAM: baseUrl + "/v2/client/gptChat/chatByStream",
    // 根据对话历史输出若干建议问题
    getAdvices: baseUrl + "/v2/client/gptChat/getAdvices",

    GET_ONLINE_APP: baseUrl + "/v2/client/app/getOnlineApp",

    queryOnlineAppsForComment:
        baseUrl + "/v2/client/app/queryOnlineAppsForComment",

    queryOnlineAppsAllowedToCopy:
        baseUrl + "/v2/client/app/queryOnlineAppsAllowedToCopy",

    cloneApps: baseUrl + "/v2/client/app/cloneApps",

    QUERY_APP_DRAFTS: baseUrl + "/v2/client/usrApp/queryAppDrafts",

    CREATR_APP: baseUrl + "/v2/client/app/createApp",

    CREATR_cancelApplyingApp: baseUrl + "/v2/client/app/cancelApplyingApp",

    CREATR_deleteAppVersion: baseUrl + "/v2/client/app/deleteAppVersion",

    CREATR_cancelApplyingApp: baseUrl + "/v2/client/app/cancelApplyingApp",

    CREATR_saveFirstSentence: baseUrl + "/v2/client/gptChat/saveFirstSentence",

    CREATR_getApp: baseUrl + "/v2/client/app/getApp",

    GET_saveAppDraft: baseUrl + "/v2/client/app/saveAppDraft",

    GET_queryAppDrafts: baseUrl + "/v2/client/app/queryAppDrafts",

    GET_getAppDraft: baseUrl + "/v2/client/app/getAppDraft",

    GET_deleteApp: baseUrl + "/v2/client/app/deleteApp",

    SSE_V2: baseUrl + "/sse/v2Connect",

    CLOSE_V2: baseUrl + "/sse/closeV2",

    STOP_OUTPUT: baseUrl + "/sse/stopOutput",

    MODIFY_PASSWORD: baseUrl + "/v2/client/user/modifyPassword",

    QUICKLY_QUERY_APPS: baseUrl + "/v2/client/app/quicklyQueryApps",

    GET_APP_PARAMS: baseUrl + "/v2/client/gptChat/getAppParams",

    UPLOAD_IMG: baseUrl + "/v2/client/file/uploadImage",

    uploadChatFile: baseUrl + "/v2/client/file/uploadChatFile",

    saveFirstSentenceV2: baseUrl + "/v2/client/gptChat/saveFirstSentenceV2",

    get_queryUsrApps: baseUrl + "/v2/client/usrApp/queryUsrApps",

    deleteUsrApp: baseUrl + "/v2/client/usrApp/deleteUsrApp",

    addUsrApp: baseUrl + "/v2/client/usrApp/addUsrApp",

    savePrompt: baseUrl + "/v2/client/app/savePrompt",

    updateUsrApp: baseUrl + "/v2/client/usrApp/updateUsrApp",

    updateTitle: baseUrl + "/v2/client/chatLog/updateTitle",

    deleteChat: baseUrl + "/v2/client/chatLog/deleteChat",

    queryCategory: baseUrl + "/v2/client/appCategory/queryCategory",

    addDocument: baseUrl + "/v2/client/document/addDocument",

    queryDocument: baseUrl + "/v2/client/document/queryDocument",

    getDocumentMapByIds: baseUrl + "/v2/client/document/getDocumentMapByIds",

    queryGoods: baseUrl + "/v2/client/goods/queryGoods",

    updateShareReward: baseUrl + "/v2/client/goods/updateShareReward",

    createOrder: baseUrl + "/order/createOrder",

    uploadDocument: baseUrl + "/v2/client/file/uploadDocument",

    queryDocFile: baseUrl + "/v2/client/document/queryDocFile",

    getDocument: baseUrl + "/v2/client/document/getDocument",

    addDocFile: baseUrl + "/v2/client/document/addDocFile",

    deleteDocFile: baseUrl + "/v2/client/document/deleteDocFile",

    addDocText: baseUrl + "/v2/client/document/addDocText",

    getDocText: baseUrl + "/v2/client/document/getDocText",

    queryDocText: baseUrl + "/v2/client/document/queryDocText",

    deleteDocText: baseUrl + "/v2/client/document/deleteDocText",

    updateApp: baseUrl + "/v2/client/app/updateApp",

    updateAppSort: baseUrl + "/v2/client/app/updateAppSort",

    cloneApp: baseUrl + "/v2/client/app/cloneApp",

    updateDocument: baseUrl + "/v2/client/document/updateDocument",

    cloneDocument: baseUrl + "/v2/client/document/cloneDocument",

    deleteDocument: baseUrl + "/v2/client/document/deleteDocument",

    addDocRefTexts: baseUrl + "/v2/client/document/addDocRefTexts",

    addOrganization: baseUrl + "/v2/client/organization/addOrganization",

    queryOrganization: baseUrl + "/v2/client/organization/queryOrganization",

    getOrganizationById: baseUrl + "/v2/client/organization/getOrganizationById",

    updateOrganization: baseUrl + "/v2/client/organization/updateOrganization",

    allowOrganizationPublic:
        baseUrl + "/v2/client/organization/allowOrganizationPublic",

    addOrgUser: baseUrl + "/v2/client/organization/addOrgUser",

    queryOrgUser: baseUrl + "/v2/client/organization/queryOrgUser",

    getAvailablePermission:
        baseUrl + "/v2/client/organization/getAvailablePermission",

    updateOrgUser: baseUrl + "/v2/client/organization/updateOrgUser",

    deleteOrgUser: baseUrl + "/v2/client/organization/deleteOrgUser",

    getUpgradePrice: baseUrl + "/v2/client/organization/getUpgradePrice",

    getUserUpgradePrice: baseUrl + "/v2/client/user/getUpgradePrice",
    getOrgGoods: baseUrl + "/v2/client/organization/getOrgGoods",

    getUsrGoods: baseUrl + "/v2/client/user/getUsrGoods",

    getAigoPoster: baseUrl + "/v2/client/file/getAigoPoster",

    queryChatDetail: baseUrl + "/v2/client/chatLog/queryChatDetail",

    deleteMessage: baseUrl + "/v2/client/chatLog/deleteMessage",

    updateMessage: baseUrl + "/v2/client/chatLog/updateMessage",

    getQrCode: baseUrl + "/v2/client/file/getQrCode",

    queryChatByAppId: baseUrl + "/v2/client/chatLog/queryChatByAppId",

    queryQuestionAnswer: baseUrl + "/v2/client/chatLog/queryQuestionAnswer",

    querySimilarQuestion: baseUrl + "/v2/client/gptChat/querySimilarQuestion",

    createJsapiSignature: baseUrl + "/wx/createJsapiSignature",

    loginByWx: baseUrl + "/auth/loginByWx",

    autoUpdateTitle: baseUrl + "/v2/client/chatLog/autoUpdateTitle",

    getOrganizationByTag:
        baseUrl + "/v2/client/organization/getOrganizationByTag",

    queryGoodsForEditting: baseUrl + "/v2/client/goods/queryGoodsForEditting",

    addGoods: baseUrl + "/v2/client/goods/addGoods",

    updateGoods: baseUrl + "/v2/client/goods/updateGoods",

    getOrgMemberGoods: baseUrl + "/v2/client/user/getOrgMemberGoods",

    queryOrgMember: baseUrl + "/v2/client/organization/queryOrgMember",

    statOrganization: baseUrl + "/v2/client/organizationData/statOrganization",

    getOrgData: baseUrl + "/v2/client/organizationData/getOrgData",

    queryOrder: baseUrl + "/order/queryOrder",

    getOrgUser: baseUrl + "/v2/client/organization/getOrgUser",

    textToSpeech: baseUrl + "/v2/client/gptChat/textToSpeech",

    speechToText: baseUrl + "/v2/client/gptChat/speechToText",

    queryOrder: baseUrl + "/order/queryOrder",

    getOrgGoods: baseUrl + "/v2/client/organization/getOrgGoods",

    importOrgUsers: baseUrl + "/v2/client/organization/importOrgUsers",

    optimizeProblem: baseUrl + "/v2/client/gptChat/optimizeProblem",

    getSimilarQuestions: baseUrl + "/v2/client/gptChat/getSimilarQuestions",

    //获取所有模型字典
    getAppModels: baseUrl + "/v2/client/organization/getAppModels",

    getAppDraftForPreview: baseUrl + "/v2/client/app/getAppDraftForPreview",
    // 修改用户昵称
    modifyNickname: baseUrl + "/v2/client/user/modifyNickname",
    // 修改用户简介
    modifyIntro: baseUrl + "/v2/client/user/modifyIntro",

    getUpgradePriceV2: baseUrl + "/v2/client/user/getUpgradePriceV2",

    /**
     * 论坛发帖
     */
    addArticle: baseUrl + "/v2/client/article/addArticle",

    /**
     * 获取帖子列表
     */
    queryArticle: baseUrl + "/v2/client/article/queryArticle",
    /**
     * 查询文章信息(宣传页)
     */
    queryArticleForAd: baseUrl + "/v2/client/article/queryArticleForAd",

    /**
     * 获取版块列表
     */
    queryBoard: baseUrl + "/v2/client/board/queryBoard",

    /**
     * 添加版块
     */
    addBoard: baseUrl + "/v2/client/board/addBoard",

    /**
     * 回答问题
     */
    replyQuestion: baseUrl + "/v2/client/article/replyQuestion",

    /**
     * 点赞
     */
    setUsrArticle: baseUrl + "/v2/client/article/setUsrArticle",

    updateArticle: baseUrl + "/v2/client/article/updateArticle",

    deleteArticle: baseUrl + "/v2/client/article/deleteArticle",

    getArticle: baseUrl + "/v2/client/article/getArticle",

    getArticleForEditing: baseUrl + "/v2/client/article/getArticleForEditing",

    getArticleForAd: baseUrl + "/v2/client/article/getArticleForAd",

    queryBoardForEditing: baseUrl + "/v2/client/board/queryBoardForEditing2",

    updateBoard: baseUrl + "/v2/client/board/updateBoard",

    deleteBoard: baseUrl + "/v2/client/board/deleteBoard",

    addComment: baseUrl + "/v2/client/comment/addComment",

    updateBoards: baseUrl + "/v2/client/board/updateBoards",

    queryComment: baseUrl + "/v2/client/comment/queryComment",

    setUsrComment: baseUrl + "/v2/client/comment/setUsrComment",

    deleteComment: baseUrl + "/v2/client/comment/deleteComment",

    generateCommentByAI: baseUrl + "/v2/client/comment/generateCommentByAI",

    addGoodsCoupon: baseUrl + "/v2/client/goodsCoupon/addGoodsCoupon",

    createCouponFdOrder: baseUrl + "/v2/client/goodsCoupon/createCouponFdOrder",

    queryGoodsCoupon: baseUrl + "/v2/client/goodsCoupon/queryGoodsCoupon",

    queryGoodsCouponFd: baseUrl + "/v2/client/goodsCoupon/queryGoodsCouponFd",

    updateGoodsCoupon: baseUrl + "/v2/client/goodsCoupon/updateGoodsCoupon",

    queryGoodsCouponFd: baseUrl + "/v2/client/goodsCoupon/queryGoodsCouponFd",

    getGoodsCouponFd: baseUrl + "/v2/client/goodsCoupon/getGoodsCouponFd",

    getGoodsCoupon: baseUrl + "/v2/client/goodsCoupon/getGoodsCoupon",

    updateGoodsCouponStatus:
        baseUrl + "/v2/client/goodsCoupon/updateGoodsCouponStatus",

    updateGoodsFdCoupon: baseUrl + "/v2/client/goodsCoupon/updateGoodsFdCoupon",

    //工作流接口//
    //获取各类选项类型定义
    workflowGetConfig: baseUrl + "/v2/tool/workflow/getConfig",
    //获取可引用参数列表
    workflowGetCanRefParams: baseUrl + "/v2/tool/workflow/getCanRefParams",
    //添加工作流
    workflowAddWorkflow: baseUrl + "/v2/tool/workflow/addWorkflow",
    //查询工作流列表
    workflowQueryWorkflow: baseUrl + "/v2/tool/workflow/queryWorkflow",
    //获取允许跨组织复制的工作流列表
    workflowQueryWorkflowsAllowedToCopy: baseUrl + "/v2/tool/workflow/queryWorkflowsAllowedToCopy",
    //获取指定的工作流
    workflowGetWorkflow: baseUrl + "/v2/tool/workflow/getWorkflow",
    //修改工作流基本信息
    workflowUpdateWorkflowBaseInfo: baseUrl + "/v2/tool/workflow/updateWorkflowBaseInfo",
    //保存工作流草稿
    workflowSaveWorkflowDraft: baseUrl + "/v2/tool/workflow/saveWorkflowDraft",
    //测试工作流草稿
    workflowTestWorkflow: baseUrl + "/v2/tool/workflow/testWorkflow",
    //获取指定节点执行结果
    workflowGetNodeResult: baseUrl + "/v2/tool/workflow/getNodeResult",
    //获取最后一次运行结果
    workflowGetLastResult: baseUrl + "/v2/tool/workflow/getLastResult",
    //复制副本
    workflowCloneWorkflow: baseUrl + "/v2/tool/workflow/cloneWorkflow",
    //批量复制工作流
    workflowCloneWorkflows: baseUrl + "/v2/tool/workflow/cloneWorkflows",
    //删除指定工作流
    workflowDeleteWorkflow: baseUrl + "/v2/tool/workflow/deleteWorkflow",
    //发布工作流
    workflowPublishWorkflow: baseUrl + "/v2/tool/workflow/publishWorkflow",
    //设置工作流为非发布状态
    workflowSetWorkflowNotPublished: baseUrl + "/v2/tool/workflow/setWorkflowNotPublished",
    //获取整个工作流执行结果
    workflowGetResult: baseUrl + "/v2/tool/workflow/getResult",
    //停止当前工作流执行
    workflowStopProcessing: baseUrl + "/v2/tool/workflow/stopProcessing",
    //查询可引用工作流列表
    workflowQueryRefWorkflow: baseUrl + "/v2/tool/workflow/queryRefWorkflow",
    //获取被引用工作流
    workflowGetRefWorkflow: baseUrl + "/v2/tool/workflow/getRefWorkflow",
    //获取工作流输入参数
    workflowGetInParams: baseUrl + "/v2/tool/workflow/getInParams",

    //测试集//
    //添加测试集
    workflowAddTestData: baseUrl + "/v2/tool/workflowTestData/addTestData",
    //删除测试集
    workflowDeleteTestData: baseUrl + "/v2/tool/workflowTestData/deleteTestData",
    //获取指定测试集
    workflowGetTestData: baseUrl + "/v2/tool/workflowTestData/getTestData",
    //获取测试集列表
    workflowQueryTestData: baseUrl + "/v2/tool/workflowTestData/queryTestData",
    //更新测试集
    workflowUpdateTestData: baseUrl + "/v2/tool/workflowTestData/updateTestData",

    //应用工具配置//
    //查询指定应用的工具配置信息
    appToolQueryAppTool: baseUrl + "/v2/tool/appTool/queryAppTool",
    //获取指定应用工具配置信息
    appToolGetAppTool: baseUrl + "/v2/tool/appTool/getAppTool",
    //添加应用工具配置
    appToolAddAppTool: baseUrl + "/v2/tool/appTool/addAppTool",
    //删除应用工具配置
    appToolDeleteAppTool: baseUrl + "/v2/tool/appTool/deleteAppTool",

    //插件接口//
    //添加插件
    pluginAddPlugin: baseUrl + "/v2/tool/plugin/addPlugin",
    //添加插件工具
    pluginAddPluginTool: baseUrl + "/v2/tool/plugin/addPluginTool",
    //复制插件副本
    pluginClonePlugin: baseUrl + "/v2/tool/plugin/clonePlugin",
    //批量复制插件
    pluginClonePlugins: baseUrl + "/v2/tool/plugin/clonePlugins",
    //删除指定插件
    pluginDeletePlugin: baseUrl + "/v2/tool/plugin/deletePlugin",
    //删除指定插件工具
    pluginDeletePluginTool: baseUrl + "/v2/tool/plugin/deletePluginTool",
    //获取指定的插件
    pluginGetPlugin: baseUrl + "/v2/tool/plugin/getPlugin",
    //获取指定的插件工具
    pluginGetPluginTool: baseUrl + "/v2/tool/plugin/getPluginTool",
    //获取被引用插件工具
    pluginGetRefPluginTool: baseUrl + "/v2/tool/plugin/getRefPluginTool",
    //发布插件
    pluginPublishPlugin: baseUrl + "/v2/tool/plugin/publishPlugin",
    //查询插件列表
    pluginQueryPlugin: baseUrl + "/v2/tool/plugin/queryPlugin",
    //获取允许跨组织复制的插件列表
    pluginQueryPluginsAllowedToCopy: baseUrl + "/v2/tool/plugin/queryPluginsAllowedToCopy",
    //查询插件工具列表
    pluginQueryPluginTool: baseUrl + "/v2/tool/plugin/queryPluginTool",
    //查询可引用插件列表
    pluginQueryRefPlugin: baseUrl + "/v2/tool/plugin/queryRefPlugin",
    //保存插件工具草稿
    pluginSavePluginToolDraft: baseUrl + "/v2/tool/plugin/savePluginToolDraft",
    //设置插件为非发布状态
    pluginSetPluginNotPublished: baseUrl + "/v2/tool/plugin/setPluginNotPublished",
    //测试插件工具
    pluginTestPluginTool: baseUrl + "/v2/tool/plugin/testPluginTool",
    //修改插件信息
    pluginUpdatePlugin: baseUrl + "/v2/tool/plugin/updatePlugin",
    //修改插件工具基本信息
    pluginUpdatePluginToolBaseInfo: baseUrl + "/v2/tool/plugin/updatePluginToolBaseInfo",
    //修改插件工具启用状态
    pluginUpdatePluginToolEnabled: baseUrl + "/v2/tool/plugin/updatePluginToolEnabled",
    //获取工具输入参数
    pluginGetInParams: baseUrl + "/v2/tool/plugin/getInParams",

    //微信群配置接口//
    //获取微信群配置列表
    appWxConfigQueryConfig: baseUrl + "/v2/client/appWxConfig/queryConfig",
    //获取微信群配置
    appWxConfigGetConfig: baseUrl + "/v2/client/appWxConfig/getConfig",
    //修改微信群配置
    appWxConfigUpdateConfig: baseUrl + "/v2/client/appWxConfig/updateConfig",

    //微微信群定时消息配置接口//
    //获取微信群定时消息列表
    appWxTimedMessageQueryMessage:
        baseUrl + "/v2/client/appWxTimedMessage/queryMessage",
    //添加微信群定时消息
    appWxTimedMessageAddMessage:
        baseUrl + "/v2/client/appWxTimedMessage/addMessage",
    //修改微信群定时消息
    appWxTimedMessageUpdateMessage:
        baseUrl + "/v2/client/appWxTimedMessage/updateMessage",
    //获取微信群定时消息
    appWxTimedMessageGMessage:
        baseUrl + "/v2/client/appWxTimedMessage/getMessage",
    //删除微信群定时消息
    appWxTimedMessageDeleteMessage:
        baseUrl + "/v2/client/appWxTimedMessage/deleteMessage",
    //批量添加同源微信群定时消息
    appWxTimedMessageAddMessages:
        baseUrl + "/v2/client/appWxTimedMessage/addMessages",
    //批量删除同源微信群定时消息
    appWxTimedMessageDeleteMessages:
        baseUrl + "/v2/client/appWxTimedMessage/deleteMessages",
    //批量修改同源微信群定时消息
    appWxTimedMessageUpdateMessages:
        baseUrl + "/v2/client/appWxTimedMessage/updateMessages",

    //用户消息接口//
    //获取最新消息
    usrMessageQueryLatestMessage:
        baseUrl + "/v2/client/usrMessage/queryLatestMessage",
    //设置全部消息已读
    usrMessageSetAllMessagesRead:
        baseUrl + "/v2/client/usrMessage/setAllMessagesRead",
    //设置消息已读
    usrMessageSetMessageRead: baseUrl + "/v2/client/usrMessage/setMessageRead",
    //删除指定消息
    usrMessageDeleteMessage: baseUrl + "/v2/client/usrMessage/deleteMessage",
    //私聊
    usrMessagePrivateChat: baseUrl + "/v2/client/usrMessage/privateChat",

    //用户分享接口//
    //保存分享信息
    usrShareSaveShare: baseUrl + "/v2/client/usrShare/saveShare",
    //点击分享内容
    doShare: baseUrl + "/v2/share",
    //获取分享信息
    usrShareGetShare: baseUrl + "/v2/client/usrShare/getShare",
    //查询分享信息
    usrShareQueryShare: baseUrl + "/v2/client/usrShare/queryShare",
    //自动生成分享内容的标题
    usrShareGenerateTitleByAI: baseUrl + "/v2/client/usrShare/generateTitleByAI",
    //删除分享信息
    usrShareDeleteShare: baseUrl + "/v2/client/usrShare/deleteShare",

    //应用标签接口//
    //获取应用标签信息列表
    queryAppTag: baseUrl + "/v2/client/appTag/queryAppTag",
    //获取应用标签信息
    getAppTag: baseUrl + "/v2/client/appTag/getAppTag",
    //保存应用标签信息
    saveAppTag: baseUrl + "/v2/client/appTag/saveAppTag",
    //删除应用标签信息
    deleteAppTag: baseUrl + "/v2/client/appTag/deleteAppTag",

    //对话文件接口//
    //删除指定文件
    deleteChatFiles: baseUrl + "/v2/client/chatFile/deleteChatFiles",
    //获取指定文件
    getChatFile: baseUrl + "/v2/client/chatFile/getChatFile",
    //获取我上传的文件列表
    queryChatFile: baseUrl + "/v2/client/chatFile/queryChatFile",

    //资源分组接口//
    //查询资源分组信息列表
    orgGroupQueryGroup: baseUrl + "/v2/client/orgGroup/queryGroup",
    //获取指定资源分组信息
    orgGroupGetGroup: baseUrl + "/v2/client/orgGroup/getGroup",
    //添加或修改资源分组
    orgGroupAddOrUpdateGroup: baseUrl + "/v2/client/orgGroup/addOrUpdateGroup",
    //删除指定的资源分组
    orgGroupDeleteGroup: baseUrl + "/v2/client/orgGroup/deleteGroup",
    //添加或删除用户的授权分组
    orgGroupAddOrDeleteUserGroups: baseUrl + "/v2/client/orgGroup/addOrDeleteUserGroups",
    //添加或删除分组的关联资源
    orgGroupAddOrDeleteGroupItems: baseUrl + "/v2/client/orgGroup/addOrDeleteGroupItems",
    
    //变量接口//
    //添加或更新变量定义信息
    addOrUpdateVariableDefine: baseUrl + "/v2/client/appVariable/addOrUpdateVariableDefine",
    //删除变量定义信息
    deleteVariableDefine: baseUrl + "/v2/client/appVariable/deleteVariableDefine",
    //获取变量定义信息
    getVariableDefine: baseUrl + "/v2/client/appVariable/getVariableDefine",
    //查询变量定义信息
    queryVariableDefine: baseUrl + "/v2/client/appVariable/queryVariableDefine",
    
    //自定义数据接口//
    //添加或修改用户自定义表
    addOrUpdateTable: baseUrl + "/v2/client/appTable/addOrUpdateTable",
    //删除用户自定义表
    deleteTable: baseUrl + "/v2/client/appTable/deleteTable",
    //删除行数据
    deleteTableRows: baseUrl + "/v2/client/appTable/deleteTableRows",
    //获取表的字段定义列表
    getTableColumns: baseUrl + "/v2/client/appTable/getTableColumns",
    //获取表数据
    getTableData: baseUrl + "/v2/client/appTable/getTableData",
    //查询用户自定义表
    queryTable: baseUrl + "/v2/client/appTable/queryTable",
    //更新表的列
    updateTableColumns: baseUrl + "/v2/client/appTable/updateTableColumns",

    //长期记忆接口//
    //添加或更新变量定义信息
    addOrUpdateLongTimeMemory: baseUrl + "/v2/client/appLongTimeMemory/addOrUpdateLongTimeMemory",
    //删除长期记忆信息
    deleteLongTimeMemories: baseUrl + "/v2/client/appLongTimeMemory/deleteLongTimeMemories",
    //查询长期记忆信息，只返回最近50条
    queryLongTimeMemory: baseUrl + "/v2/client/appLongTimeMemory/queryLongTimeMemory",
};
export default { HTTP_URI, baseUrl };
