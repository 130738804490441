<template>
    <div>
        <el-dialog title="" :visible.sync="dialogVisible" width="40%" :close-on-click-modal="false" append-to-body :show-close="false" custom-class="appSetDialog">
            <el-container>
                <el-aside width="200px">
                    <div class="aside-box">
                        <el-input
                            placeholder="请输入内容"
                            prefix-icon="el-icon-search"
                            v-model="pageData.name"
                            @input="searchChange">
                        </el-input>
                        <div class="menu-box" :class="showMenu=='plugins'?'selected-box':''" @click="changeMenu('plugins')">
                            <i class="iconfont icon-chajianku-chajianku"></i>
                            <span>组织插件</span>
                        </div>
                        <div class="menu-box" :class="showMenu=='workFlow'?'selected-box':''" @click="changeMenu('workFlow')">
                            <i class="iconfont icon-gongzuoliu"></i>
                            <span>组织工作流</span>
                        </div>
                    </div>
                    <div class="aside-box"></div>
                </el-aside>
                <el-container>
                    <el-main>
                        <div class="head-box">
                            <div class="head-search-box">
                            </div>
                            <div class="head-close-box">
                                <i class="el-icon-close" @click="handleClose"></i>
                            </div>
                        </div>
                        <el-table
                        v-if="showMenu=='plugins'"
                        class="pluginsTable"
                        :data="tableData"
                        style="width: 100%"
                        max-height="500"
                        :show-header="false">
                            <el-table-column>
                                <template slot-scope="scope">
                                    <div class="table-column">
                                        <el-collapse v-model="activeNames" @change="handleChange">
                                            <el-collapse-item :name="scope.$index">
                                                <template slot="title">
                                                    <div class="plugin-box">
                                                        <div class="column-info">
                                                            <el-tooltip effect="dark" content="打开操作窗口" placement="top" :hide-after="1500">
                                                                <p class="name" @click.stop="toPluginEdit(scope.row)">{{scope.row.name}}</p>
                                                            </el-tooltip>
                                                        </div>
                                                        <div class="column-info">
                                                            <p class="description">{{scope.row.description}}</p>
                                                        </div>
                                                        <div class="column-info">
                                                            <p class="length">{{scope.row.tools.length}}个工具</p>
                                                        </div>
                                                    </div>
                                                </template>
                                                <div class="tool-box" v-for="(item,index) in scope.row.tools" :key="index">
                                                    <div class="tool-item">
                                                        <div class="column-info">
                                                            <el-tooltip effect="dark" content="打开操作窗口" placement="top" :hide-after="1500">
                                                                <p class="name" @click.stop="toToolEdit(item)">{{item.toolName}}</p>
                                                            </el-tooltip>
                                                        </div>
                                                        <div class="column-info">
                                                            <p class="description">{{item.toolDescription}}</p>
                                                        </div>
                                                        <div class="column-info">
                                                            <span v-for="(inParamItem,inParamIndex) in item.inParamIds" :key="inParamIndex">
                                                                <el-tag size="mini" type="info" v-if="item.paramMapById[inParamItem].name">{{item.paramMapById[inParamItem].name}}</el-tag>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="tool-opt">
                                                        <el-button size="mini" @click.stop="addClick(item)">添加</el-button>
                                                    </div>
                                                </div>
                                            </el-collapse-item>
                                        </el-collapse>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-table
                        v-else
                        class="workFlowTable"
                        :data="tableData"
                        style="width: 100%"
                        max-height="500"
                        :show-header="false">
                            <el-table-column>
                                <template slot-scope="scope">
                                    <div class="table-column">
                                        <div class="column-left">
                                            <div class="column-info">
                                                <el-tooltip effect="dark" content="打开操作窗口" placement="top" :hide-after="1500">
                                                    <span class="name" @click.stop="toEdit(scope.row)">{{scope.row.name}}</span>
                                                </el-tooltip>
                                                <span class="published" v-for="(item,index) in options" :key="index">
                                                    <span v-if="item.value==scope.row.published">{{ item.label }}</span>
                                                </span>
                                            </div>
                                            <div class="column-info">
                                                <span class="description">{{scope.row.description}}</span>
                                            </div>
                                            <div class="column-info" v-if="scope.row.draftEntity">
                                                <div class="param-box">
                                                    <el-tag size="mini" type="info" v-for="(item,key,index) in scope.row.draftEntity.paramMapById" :key="index">
                                                        {{item.name}}
                                                    </el-tag>
                                                </div>
                                            </div>
                                            <div class="column-info">
                                                <span class="createTime">创建于 {{scope.row.createTime}}</span>
                                            </div>
                                        </div>
                                        <div class="column-right">
                                            <div class="opt-box" @click.stop="addClick(scope.row)">添加</div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-main>
                    <el-footer>
                        <div class="footer-box">
                            <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="pageData.pageNumber"
                            :page-size="pageData.pageSize"
                            layout="total, prev, pager, next"
                            :total="pageData.total">
                            </el-pagination>
                        </div>
                    </el-footer>
                </el-container>
            </el-container>
        </el-dialog>
    </div>
</template>

<script>
    import REQUEST from '@/api/http'
    import { Container, Aside, Main, Footer } from 'element-ui';
    export default {
        data() {
            return {
                showMenu: 'plugins',
                appId: 1,
                dialogVisible: false,
                pageData: {
                    published: 1,
                    name: '',
                    pageNumber: 1,
                    pageSize: 10,
                    total: 0
                },
                tableData: [],
                options: [{
                        value: 0,
                        label: '未发布'
                    }, {
                        value: 1,
                        label: '已发布'
                    }
                ],
                showType: 0,// 0:未添加;1:已添加;2:移除
                activeNames: []
            }
        },
        components: {
            'el-container': Container,
            'el-aside': Aside,
            'el-main': Main,
            'el-footer': Footer
        },
        mounted(){
        },
        methods:{
            changeMenu(e){
                this.showMenu = e
                this.pageData = {
                    published: 1,
                    name: '',
                    pageNumber: 1,
                    pageSize: 10,
                    total: 0
                }
                if(this.showMenu=='plugins'){
                    this.queryRefPlugin()
                }else{
                    this.queryWorkflow()
                }
            },
            handleChange(val) {
                // console.log(val);
            },
            open(appId) {
                this.showMenu = 'plugins'
                this.appId = appId
                this.dialogVisible = true
                this.pageData = {
                    published: 1,
                    name: '',
                    pageNumber: 1,
                    pageSize: 10,
                    total: 0
                }
                this.queryRefPlugin()
            },
            handleClose() {
                this.dialogVisible = false
            },
            //查询插件列表
            async queryRefPlugin() {
                let param = {
                    name: this.pageData.name,
                    pageNumber: this.pageData.pageNumber,
                    pageSize: this.pageData.pageSize,
                }
                var res = await this.$https('pluginQueryRefPlugin', param)
                if(res.status==200){
                    this.tableData = res.data.records
                    this.pageData.total = Number(res.data.total)
                }
            },
            //查询工作流列表
            async queryWorkflow() {
                let param = {
                    isDraft: false,
                    appIdForFiltering: this.appId,
                    published: this.pageData.published,
                    name: this.pageData.name,
                    pageNumber: this.pageData.pageNumber,
                    pageSize: this.pageData.pageSize,
                }
                var res = await this.$https('workflowQueryWorkflow', param)
                if(res.status==200){
                    this.tableData = res.data.records
                    this.pageData.total = Number(res.data.total)
                }
            },
            searchChange() {
                this.pageData.pageNumber = 1
                if(this.showMenu=='plugins'){
                    this.queryRefPlugin()
                }else{
                    this.queryWorkflow()
                }
            },
            handleSizeChange(val) {
                this.pageData.pageSize = val
                if(this.showMenu=='plugins'){
                    this.queryRefPlugin()
                }else{
                    this.queryWorkflow()
                }
            },
            handleCurrentChange(val) {
                this.pageData.pageNumber = val
                if(this.showMenu=='plugins'){
                    this.queryRefPlugin()
                }else{
                    this.queryWorkflow()
                }
            },
            toPluginEdit(row) {
                this.$store.commit('SET_pluginId', row.id)
                window.open(location.origin + `/OrgannizationDetail/PluginTool/${row.id}`)
            },
            toToolEdit(item) {
                this.$store.commit('SET_toolId', item.toolId)
                window.open(location.origin + `/createdTool/${item.toolId}`)
            },
            async addClick(row) {
                console.log(row)
                let obj = {
                    inParams:[],
                    refType:'',
                    refId:'',
                    refName:'',
                    refDescription:'',
                }
                let param = {
                    online: true
                }
                if(this.showMenu=='plugins'){
                    obj.refType = 2
                    obj.refId = row.toolId
                    obj.refName = row.toolName
                    obj.refDescription = row.toolDescription
                    param.id = row.toolId
                    var res = await REQUEST.POST_QUERY_ORG('pluginGetInParams', param)
                    if(res.status==200){
                        obj.inParams = res.data
                    }
                }else{
                    obj.refType = 3
                    obj.refId = row.id
                    obj.refName = row.name
                    obj.refDescription = row.description
                    param.id = row.id
                    var res = await REQUEST.POST_QUERY_ORG('workflowGetInParams', param)
                    if(res.status==200){
                        obj.inParams = res.data
                    }
                }
                this.$emit('execute',obj)
                this.handleClose()
            }
        }
    }
</script>

<style lang="scss" scoped>
.el-container {
    min-height: 500px;
}

.el-aside {
    background-color: #EBEDF0;
    color: #333;
    border-radius: 10px 0 0 10px;
    .aside-box {
        margin: 10px;
        .create-btn {
            width: 100%;
            margin: 10px 0;
            padding: 10px;
        }
    }
}

.menu-box {
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    i {
        padding: 0 10px 0 40px;
        color: #181C21;
    }
}

.menu-box:hover {
    background: #E1E3E7;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    color: #666;
    i {
        padding: 0 10px 0 40px;
        color: #4d52e8c2;
    }
}

.selected-box {
    background: #E1E3E7;
    i {
        color: #4D53E8;
    }
}

.el-main {
    background-color: #F7F7FA;
    border-radius: 0 8px 0 0;
    padding: 10px;
    .head-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .head-search-box {
            .el-select {
                width: 111px;
            }
        }
        .head-close-box {
            i {
                cursor: pointer; 
                padding: 5px;  
            }
            i:hover {
                cursor: pointer;
                color: #3B3EC4; 
                padding: 5px;
                border-radius: 25%;
                background: #FFFFFF;
            }
        }
    }
    .pluginsTable {
        .table-column {
            display: flex;
            .plugin-box {
                padding: 0 10px;
                .column-info {
                    display: flex;
                    align-items: center;
                    margin: 3px 0;
                    .name {
                        color: #000;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                    .name:hover {
                        color: #333;
                    }
                    .published {
                        color: #5f5f5f;
                        font-size: 12px;
                    }
                    .description {
                        color: #616161;
                        font-size: 12px;
                    }
                    .length {
                        padding: 0 5px;
                        border-radius: 8px;
                        background: #f4f4f5;
                        color: #7d7d7d;
                        font-size: 12px;
                    }
                    .el-tag {
                        margin-right: 5px;
                    }
                    .createTime {
                        color: #919191;
                        font-size: 12px;
                    }
                    .param-box {
                        display: flex;
                        width: 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        .el-tag {
                            margin-right: 5px;
                            padding: 0 3px;
                            height: 18px;
                            line-height: 18px;
                        }
                    }
                }
            }
            .tool-box {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                border-bottom: 1px solid #e4e4e4;
                .tool-item {
                    width: calc(100% - 80px);
                    overflow: auto;
                    .column-info {
                        display: flex;
                        align-items: center;
                        margin: 3px 0;
                        .name {
                            color: #000;
                            margin-right: 10px;
                            cursor: pointer;
                        }
                        .name:hover {
                            color: #333;
                        }
                        .published {
                            color: #5f5f5f;
                            font-size: 12px;
                        }
                        .description {
                            color: #616161;
                            font-size: 12px;
                        }
                        .createTime {
                            color: #919191;
                            font-size: 12px;
                        }
                        .param-box {
                            display: flex;
                            width: 300px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                            .el-tag {
                                margin-right: 5px;
                                padding: 0 3px;
                                height: 18px;
                                line-height: 18px;
                            }
                        }
                    }
                }
                .tool-opt {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 80px;
                }
            }
            
            .tool-box:last-child {
                border: none;
            }
        }
    }
    .workFlowTable {
        .table-column {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            .column-left {
                .column-info {
                    display: flex;
                    align-items: center;
                    margin: 3px 0;
                    .name {
                        color: #000;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                    .name:hover {
                        color: #333;
                    }
                    .published {
                        color: #5f5f5f;
                        font-size: 12px;
                    }
                    .description {
                        color: #616161;
                        font-size: 12px;
                    }
                    .createTime {
                        color: #919191;
                        font-size: 12px;
                    }
                    .param-box {
                        display: flex;
                        width: 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        .el-tag {
                            margin-right: 5px;
                            padding: 0 3px;
                            height: 18px;
                            line-height: 18px;
                        }
                    }
                }
            }
            .column-right {
                display: flex;
                align-items: center;
                .opt-box {
                    cursor: pointer;
                    background: #FFFFFF;
                    border-radius: 6px;
                    padding: 5px 10px;
                }
                .opt-box:hover {
                    cursor: pointer;
                    color: #FFFFFF;
                    background: #3B3EC4;
                    border-radius: 6px;
                    padding: 5px 10px;
                }
            }
        }
    }
}
.el-footer {
    background-color: #F7F7FA;
}
.footer-box {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}
.el-table {
    ::-webkit-scrollbar {
        display: none;
    }
}

/* 修改表格背景颜色 */
::v-deep .el-table .el-table__body tr.el-table__row {
    background-color: #F7F7FA;
}
/* 修改表格行悬停背景颜色 */
::v-deep .el-table .el-table__body tr.el-table__row:hover > td {
    background-color: #EFEFF2;
}
::v-deep .appSetDialog {
    .el-dialog__header {
        padding: 0 !important;
    }
    .el-dialog__body {
        padding: 0 !important;
    }
}
.el-collapse {
    width: 100%;
}
::v-deep .el-collapse-item__header {
    height: auto !important;
    line-height: 20px !important;
}
</style>