/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 20:49:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-29 10:05:54
 * @FilePath: /AIGo.work/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import hljs from 'highlight.js'
import SlideVerify from 'vue-monoplasty-slide-verify'
import Clipboard from 'clipboard'
import '@/assets/css/reset.css'
import '@/assets/css/style.scss'
// import ElementUI from 'element-ui'
import {Collapse,CollapseItem, Button,Message, Progress, DatePicker, Submenu,Empty, MenuItemGroup, MenuItem, Menu, CheckboxGroup, RadioButton, InputNumber, RadioGroup, Switch, Popover, Pagination, Checkbox, Select, Drawer, Input , Radio, Tooltip,TabPane,Tabs, Tag,Upload, MessageBox, Loading, Dialog, Option, FormItem, Form, Col,Table,TableColumn,Dropdown, DropdownMenu, DropdownItem, Card, Row} from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import { REQUEST } from '@/api/http'
import {clearStore, convert_fen_to_yuan, getQueryStrByUrl, isMobile} from '@/utils/index'
import Modal from '@/components/modal/modal.vue'//自定义loading
import './assets/css/element-variables.scss'
import VConsole from 'vconsole';
import {dot2Num} from './utils/index.js'
import infiniteScroll from "vue-infinite-scroll";
import "@/assets/css/markdown.css"
import "@/assets/css/viewer.css"
import 'animate.css';
import i18n from './service/lang/index.js'

import { dbClick } from './components/directive/dbClick'
import { touchClick } from './components/directive/touchClick'
import { scrollBottom } from './components/scrollBottom'
import { scrollToUp } from './components/directive/scrollToUp'
import { scroll } from './components/directive/scroll'
import QuoteSet from '@/components/appSet/quoteSet.vue';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import './assets/css/typingAnim.css'

import VueSSE from 'vue-sse';
import { clickOut } from './components/directive/clickOut'
import { clickOut2 } from './components/directive/clickOut2'

import { modelTypeDictionary } from './service/dictionary'
import MyMessage from './components/message/MyMessage'
import './assets/icon-font/iconfont.css'
import { permision } from './components/directive/permision'
import AppParameSetDialog from './components/AppParameSetDialog/AppParameSetDialog.js'
// import UpdateDialog from './components/updateDialog/UpdateDialog.js'

import UpdateDialog from './components/updateDialog/UpdateDialog.vue'

// import { Button as VantButton } from 'vant';
import 'vant/lib/button/style';
// Vue.use(VantButton);

import { DatetimePicker as VantDatetimePicker, Popup as VantPopup  } from 'vant';
import 'vant/lib/datetime-picker/style';
import 'vant/lib/popup/style';
 
Vue.use(VantDatetimePicker);
Vue.use(VantPopup);

Vue.use(VueViewer, {
    defaultOptions:{
        fullscreen:false,
        rotatable:false,
        title:false,
        // zoomable:false,
        scalable:false,
        zIndex: 9999,
        zoomRatio:0.4,
        toolbar:!isMobile()
    }
});

Vue.config.productionTip = false
Vue.prototype.$https = REQUEST.POST
Vue.prototype.$https.get = REQUEST.GET
Vue.prototype.$get = REQUEST.GET
Vue.prototype.$post = REQUEST.POST_NOLOADING
Vue.prototype.clearStore = clearStore
Vue.use(SlideVerify)
Vue.use(infiniteScroll);
// Vue.use(ElementUI)
Vue.component('QuoteSet', QuoteSet);
Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Message.name, Message);
Vue.component(Drawer.name, Drawer);
Vue.component(Input.name, Input);
Vue.component(Dialog.name, Dialog);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(Card.name, Card);
Vue.component(Row.name, Row);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Col.name, Col);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Option.name, Option);
Vue.component(Upload.name, Upload);
Vue.component(Tag.name, Tag);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Radio.name, Radio);
Vue.component(Tooltip.name, Tooltip);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Pagination.name, Pagination);
Vue.component(Popover.name, Popover);
Vue.component(RadioButton.name, RadioButton);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Switch.name, Switch);
Vue.component(InputNumber.name, InputNumber);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Collapse.name, Collapse);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Menu.name, Menu);
Vue.component(Submenu.name, Submenu);
Vue.component(MenuItemGroup.name, MenuItemGroup);
Vue.component(Empty.name, Empty);
Vue.component(Progress.name, Progress);
Vue.component(DatePicker.name, DatePicker);
// Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$alert = MessageBox.alert;

Vue.use(hljs)

// using defaults
Vue.use(VueSSE);
Vue.component('b-modal', Modal)
Vue.component('updateDialog', UpdateDialog)

if(isMobile()){
  const vConsole = new VConsole();
  document.getElementById("__vconsole").style.display = "none"
}

// 提交验证
Vue.directive('clickOutside', {
  // 初始化指令
  bind(el, binding, vnode) {
    function clickHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = clickHandler;
    document.addEventListener('click', clickHandler);
  },
  update() { },
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
});
//封装成一个指令,highlight是指令的名称
Vue.directive('highlight', (el) => {
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach((block,index) => {

  // 创建ol标签元素
  let ol = document.createElement("ol");
  let div = document.createElement("div")
  div.className = 'copy_div'
  div.setAttribute('id', 'copy_'+ index)
  div.innerText = '复制'
  
    // 2.根据换行符获取行数，根据获取的行数生成行号
    let rowCount = block.outerHTML.split('\n').length;
    for(let i = 1;i < rowCount;i++){
      
      // 创建li标签元素
      let li = document.createElement("li");
      let text = document.createTextNode(i);
      // 将生成的行号添加到li标签中
      li.appendChild(text);
      // 将li标签添加到ol标签中
      ol.appendChild(li);
      
    }
    // 为ol标签添加class名
    ol.className = 'pre-numbering';
    div.addEventListener('click',function(){
      let clipboard = new Clipboard('.copy_div', {
        text: () => {
          return this.parentNode.querySelector('code')
        }
      })
      clipboard.on('success', () => {
        // alert('复制成功')
        Message({
            message: '复制成功',
            type: 'success'
        });
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        Message({
            message: '复制失败！该浏览器不支持自动复制,请手动复制。',
            type: 'danger'
        });
        // alert('该浏览器不支持自动复制,请手动复制！')
        clipboard.destroy()
      })
    })
    block.parentNode.appendChild(ol);
    block.parentNode.appendChild(div);
    hljs.highlightBlock(block)
})
})
Vue.directive('removeAriaHidden', {
    bind(el, binding) {
        const ariaEls = el.querySelectorAll('.el-radio__original')
        ariaEls.forEach((item) => {
            item.removeAttribute('aria-hidden')
        })
    }
})
Vue.filter("clearDot", (num)=>{
  return Math.trunc(num)
})
Vue.filter("dot2Num", (num)=>{
  return dot2Num(num)
})
Vue.filter("getModelTypeLabel", (num)=>{
  
  for(var i = 0; i < modelTypeDictionary.length; i++){
    if(modelTypeDictionary[i].type == num){
      return modelTypeDictionary[i].text
    }
  }
})
Vue.filter("convert_fen_to_yuan", (num)=>{
    return convert_fen_to_yuan(num)
})

Vue.directive('clickOut',  clickOut)
Vue.directive('clickOut2',  clickOut2)
Vue.directive('dbClick',  dbClick)
Vue.directive('touchClick',  touchClick)
Vue.directive('scrollBottom',  scrollBottom)
Vue.directive('scrollToUp',  scrollToUp)
Vue.directive('scroll',  scroll)

// 滚动加载更多
Vue.directive('loadMore', {
    bind(el, binding) {
        // 获取element，定义scroll
        let select_dom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        select_dom.addEventListener('scroll', function () {
        let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
        if (height) {
            binding.value()
        }
        })
    }
});

Vue.directive('permision',  permision)

Vue.use(MyMessage);

Vue.use(AppParameSetDialog);
// Vue.use(UpdateDialog);

var vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this //安装全局事件总线，起个名叫$bus，把当前vm给特
  }
}).$mount('#app')

export function getVueObject(){
    return vue
}