<template>

    <div class="bg" v-if="visible&&isOn">
        <div class="alphaBg"></div>
        <div class="appPreSet"  >

            <div class="text">AIGO已发布新版本，请点击确定按钮刷新页面</div>
            <div class="updateInfo">
                <!-- <div class="item-div">更新版本号：</div> -->
                <div class="item-div updateInfoTime">更新时间：{{ updateTime }}</div>
                <div class="item-div updateInfoTitle">更新内容：</div>
                <div class="updateInfoContent">
                    <div class="content-item" v-for="(item,index) in updateInfo" :key="index">
                        <span>{{ item.no+"：" }}</span>
                        <span>{{ item.text }}</span>
                    </div>
                </div>
            </div>
            <!-- <a class="sureBtn" :href="link" @click="visible=false">确定</a> -->
            <div class="sureBtn" :href="link" @click="handleClose">确定</div> 

        </div>
    </div>

</template>
  
<script>

import REQUEST from '@/api/http'
import { getQueryString, isMobile } from '@/utils'
import { mapState } from 'vuex'

export default {
    components: {},
    props:{
        visible:Boolean,
    },
    data() {
        return {
            isOn: true,
            link:"",
            updateTime: '2024.09.04',
            updateInfo: [
                { no: 1, text: '星舰设置开放AI评论功能开关，星舰智能体添加用于评论功能开关' },
                { no: 2, text: '星舰文章详情页面排版样式调整' },
                { no: 3, text: '星舰文章评论功能优化，添加AI评论等功能' },
            ]
        }
    },
    async mounted(){
        this.phone = JSON.parse(window.localStorage.getItem('phone'))
        
        // this.link = location.href + "?t=" + new Date().getTime()

    },
    computed:{
        ...mapState([]),
        width(){
            if(isMobile()){
                return "100%"
            }
            return "100%"
        },

    },
    methods:{
        handleClose(){
            this.$emit('update:visible', false)
            window.location.reload(true);
        },
    }
}
</script>
  
<style scoped lang="scss">

.bg{
    width: 100%;
    height: 100%;
    position:fixed;
    left: 0;
    top: 0;
    z-index: 999999;
}
.alphaBg{
    width: 100%;
    height: 100%;
    position:absolute;
    left: 0;
    top: 0;
    background: #00000093;

}

.appPreSet{
    width: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgb(255, 255, 255);
    border-radius: 12px;
    box-shadow: rgba(146, 143, 143, 0.4) 0px 2px 15px 0px;
    border: 1px solid rgb(60, 82, 105);
    padding: 30px 0;
    z-index: 999999;
    max-height: 500px;
}
.text{
    text-align: center;
    color: #666;
    font-size: 16px;
    padding: 20px 20px 20px;
    line-height: 28px;
}
.updateInfo{
    padding: 0 120px;
    .item-div{
        padding: 10px 0;
    }
    .updateInfoContent{
        max-height: 285px;
        overflow-y: auto;
        .content-item{
            padding: 10px 0;
        }
    }
}
.sureBtn{
    width: 100px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #6c4eff;
    color: #fff;
    display: block;
    font-size: 13px;
    border-radius: 6px;
    margin: 20px auto 0;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .appPreSet{
        width: 90%;
    }
    .updateInfo{
        padding: 0 10px;
    }
}

</style>