<template>
    <div class="bodys">

        <div class="mains" :class="{phone:phone,openMenu}">
            <div class="headers">
                <div class="headerLeft" v-if="!anonymous">
                    <div class="menuBtn" @click="clickMenuBtn" v-show="inAppPage">
                        <img src="../assets/app/menu.png">
                    </div>
                    <div v-if="!showType" class="goHome" @click="goHomeBtnClick">主页</div>
                    <div v-if="showType" class="goHome" @click="goStarShip">
                        <div class="showIconLeft" v-if="orgInfo">
                            <img class="orgIcon" :src="orgInfo.icon" >
                            <div class="orgName" :class="{isLimit:phone}">{{ orgInfo.name }}</div>
                        </div>
                    </div>
                    <!-- <div class="inviteBtn" v-if="topInviteBtnShow" @click="inviteBtnClick">邀请赚钱</div> -->
                </div>
                <div class="headerLeft" v-else>
                </div>

                <div class="headRight">

                    <div class="vipDoor" v-if="topVipBtnShow" @click="buyBtnClick">
                        <img src="../assets/vipDoor.png">
                    </div>

                    <template v-if="anonymous">
                        <div>
                            <el-button size="mini" type="text" @click="logOutAnonymouse">退出游客状态</el-button>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="haveUserInfo && (userId && userId>0)">
                            <myPageDialog class="myPageDialog" @logout="handleCommand('signOut')" @invite="handleCommand('invite')" @myOrg="handleCommand('organization')">
                                <div class="dropsownWrapInner">
                                    <img :src="(avatar && avatar != 'https://static.aigo.work/head_default.jpg')?avatar:require('@/assets/WechatIMG3.jpeg')" class="tx_box" :style="{'borderColor':scoket.color}"/>
                                    <div class="topNickname">{{ nickName }}</div>
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </div>
                            </myPageDialog>
                        </div>
                        <div v-if="!haveUserInfo || (userId && userId<0) || !userId">
                            <el-button size="mini" type="text" @click="openModal">登录/注册</el-button>
                        </div>
                    </template>
                </div>

            </div>
            <div class="mian">
                <router-view ref="child"></router-view>
            </div>
        </div>
        <LayOut ref="layout"></LayOut>
        <!-- <b-modal
            :title="showPage ?'欢迎登录':'用户注册'"
            :dialogVisible="loginVisible"
            :okText="''"
            :width="'400px'"
            :showClose="true"
            @cancel="loginCLoseClick"
            :loading="!loginVisible"
            :cancelText="''">
            <Login v-if="showPage"
                @success="successLogin"
                @forgetPwd="forgetPwd"
                @chanePage="chanePage">
            </Login>
            <Reg v-else
                :regType="reg_type"
                @forgetPwd="forgetPwd"
                @chanePage="chanePage">
            </Reg>

        </b-modal> -->

    <!-- 修改密码 -->
    <b-modal :title="'修改密码'"
      :dialogVisible="pwd"
      :width="'400px'"
      @cancel="cancel"
      @ok="changePwd"
      :loading="!pwd">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm">
        <!-- <el-form-item
          label="旧密码"
          prop="oldpwd">

          <el-input
            :type="type"
            placeholder="请输入密码"
            v-model="ruleForm.oldpwd">
            <i slot="suffix"
              @click="type == 'password'? type ='text':type ='password'"
              class="el-input__icon el-icon-view"></i>
          </el-input>
        </el-form-item> -->
        <el-form-item
          label="新密码"
          prop="password">
          <el-input
            :type="type1"
            placeholder="请输入密码"
            v-model="ruleForm.password">
            <i slot="suffix"
              @click="type1 == 'password'? type1 ='text':type1 ='password'"
              class="el-input__icon el-icon-view"></i>
          </el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="passwords">
          <el-input
            :type="type2"
            placeholder="请输入密码"
            v-model="ruleForm.passwords">
            <i slot="suffix"
              @click="type2 == 'password'? type2 ='text':type2 ='password'"
              class="el-input__icon el-icon-view"></i>
          </el-input>
        </el-form-item>
      </el-form>
    </b-modal>
    <!-- 修改头像 -->
    <b-modal :title="'更换头像'"
      :dialogVisible="avaterModal"
      :width="'400px'"
      :okText="''"
      @cancel="avaterModal = false"
      :cancelText="''"
      :loading="!avaterModal">
      <div class="avaterx"
        v-loading="loadingImg">
        <img :src="(avatar && avatar != 'https://static.aigo.work/head_default.jpg')?avatar:require('@/assets/WechatIMG3.jpeg')"
          style="width:200px;height: 200px;margin-bottom: 30px;border:1px solid #e6e6e6;border-radius: 5px;" />
        <el-upload
          class="upload-demo"
          :action="action"
          :show-file-list="false"
          :headers="headers"
          :before-upload="handleBefore"
          :on-success="handleSuccess">
          <el-button
            size="small"
            type="primary">更换头像</el-button>
        </el-upload>
      </div>

    </b-modal>

    <ChangeAvatarDialog :visible.sync="ChangeAvatarDialogVisible"></ChangeAvatarDialog>


    <b-modal :title="'注册或绑定'" :dialogVisible="afterQrcodeVisible" :width="'400px'" :cancelText="''" :okText="''" @cancel="afterQrcodeClose">
      <afterQrcode :openId="openId" @chanePage="successLogin"></afterQrcode>
    </b-modal>


      <el-dialog title="会员信息"
                 :visible.sync="vipInfoModel"
                 :width="vipDialogwidth"
                 :fullscreen="phone"
                 center
                 class="vipDialog"
                 :close-on-click-modal="false">
          <!--form-->
          <vconsoleSwitch class="vconsoleSwitch"></vconsoleSwitch> 

              <div class="topWrap">
                <div>
                  <span class="myPackage">我的套餐：</span>
                  <span class="memberType">{{ vipInfo.name }}</span>
                  <!-- <span class="time" v-if="vipInfo.name == '免费版'">永不过时</span>
                  <span class="time" v-else>{{ vipInfo.expiryTime }}</span> -->
                </div>
                <div class="buyBtn" @click="buyBtnClick">购买会员</div>
              </div>

              <div class="vipInfoWrap">
                <div class="item">
                  <img src="../assets/yes.png">
                  <span class="infoText" v-if="vipInfo.name == '免费版'">永久免费</span>
                  <span class="infoText" v-else>到期时间：{{ vipInfo.expiryTime }} </span>
                </div>
                <div class="item">
                  <img src="../assets/yes.png">
                  <span class="infoText" v-if="vipInfo.times >= 0">支持AI3.5，每天{{ vipInfo.times }}次</span>
                  <span class="infoText" v-else>支持AI3.5，无限次数</span>
                </div>
                <div class="item">
                  <img src="../assets/yes.png">
                  <span class="infoText" v-if="vipInfo.limitToken >= 0">{{ vipInfo.limitToken }}个token(AI3.5)</span>
                  <span class="infoText" v-else>不限token(AI3.5)</span>
                </div>

                <div class="item">
                  <img src="../assets/yes.png">
                  <span class="infoText" v-if="vipInfo.suGpt4">
                    <template v-if="vipInfo.limitTimesGpt4 >= 0">
                      支持AI4.0，每天赠送{{ vipInfo.limitTimesGpt4 }}次
                    </template>
                    <template v-else>
                      支持AI4.0，次数不限
                    </template>
                  </span>
                  <span v-else class="infoText">不支持AI4.0</span>
                </div>

                <div class="item">
                  <img src="../assets/yes.png">
                  <span class="infoText">{{ vipInfo.nodeCount }}个节点</span>
                </div>

                <div class="item">
                  <img src="../assets/yes.png">
                  <span class="infoText">上下文：{{ vipInfo.contextCount }}</span>
                </div>

              </div>

              <div class="useInfo">使用信息</div>
              <div class="myVipInfoWrap">
                <div class="vipInfoItem">今日AI3.5剩余：
                  <span v-if="gpt3Total && gpt3Total >= 0">{{ gpt3Total }}次</span>
                  <span v-else>不限次数</span>
                </div>
                <div class="vipInfoItem">今日AI4.0剩余：
                  <span v-if="gpt4Total && gpt4Total >= 0">{{ gpt4Total }}次</span>
                  <span v-else>不限次数</span>
                </div>
              </div>

          <!-- <el-form ref="ruleForm"
                   :model="vipInfo"
                   :rules="rules"
                   label-position="left"
                   label-width="120px">

              <el-form-item label="会员套餐：">
                  <div>{{ vipInfo.name }}</div>
              </el-form-item>

              <el-form-item label="到期时间：" v-if="vipInfo.name != '免费版'">
                    <div>{{ vipInfo.expiryTime }}</div>
              </el-form-item>

              <el-form-item label="价格：">
                  <div>{{ vipInfo.price }}</div>
              </el-form-item>

              <el-form-item label="原价：">
                  <div>{{ vipInfo.oldPrice }}</div>
              </el-form-item>

              <el-form-item label="AI3.5 次数/日：">
                  <div v-if="vipInfo.times >= 0">{{vipInfo.times}}</div>
                  <div v-else>无限次</div>
              </el-form-item>

              <el-form-item label="AI4 次数/日：" v-if="vipInfo.suGpt4 == true">

                  <div v-if="vipInfo.limitTimesGpt4 >= 0">{{ vipInfo.limitTimesGpt4 }}</div>
                  <div v-else>无限次</div>
              </el-form-item>

              <el-form-item label="token(AI3.5)：">
                  <div v-if="vipInfo.limitToken >= 0">{{ vipInfo.limitToken }}</div>
                  <div v-else>无限次</div>
              </el-form-item>

              <el-form-item label="支持AI4.0：">
                  <div v-if="vipInfo.suGpt4">支持</div>
                  <div v-else>不支持</div>
              </el-form-item>

              <el-form-item label="token(AI4.0)：" v-if="vipInfo.suGpt4 == true">
                  <div v-if="vipInfo.limitTokenGpt4 >= 0">{{ vipInfo.limitTokenGpt4 }}</div>
                  <div v-else>无限次</div>
              </el-form-item>

              <el-form-item label="节点数：">
                  <div>{{ vipInfo.nodeCount }}</div>
              </el-form-item>

              <el-form-item label="上下文：">
                  <div>{{ vipInfo.contextCount }}</div>
              </el-form-item>

          </el-form> -->
          <!-- 表单结束 -->
          <!--操作按钮-->
          <span slot="footer" class="dialog-footer">
              <el-button @click="handleClose">关 闭</el-button>
          </span>
      </el-dialog>

      <promotionDialog :visible.sync="promotionDialogVisible"></promotionDialog>
      <changePwd :visible.sync="changePwdVisible" @modifyClose="modifyClose"></changePwd>

      <bind v-if="afterQrcodeShow"></bind>

  </div>
</template>

<script>
import md5 from 'md5'
import LayOut from './layOut.vue'
import Login from './login.vue'
import Reg from './reg.vue'
import Notice from '@/components/notice/notice.vue'
import { mapState } from 'vuex'
import { HTTP_URI } from '@/api/api'
import vconsoleSwitch from '../components/vconsoleSwitch.vue'
import {getQueryStrByUrl, getQueryString, gotoHref, isAnonymous, cancalAnonymous, isWx, routerPush} from "@/utils/index.js"
import {isMobile} from '../utils/index.js'
import promotionDialog from "../views/home/promotionDialog.vue"
import {hasShowVipPic, removeHasShowVipPic, setTodayHasShowVipPic} from "../service/showVipPic.js"
import afterQrcode from "./afterQrcode.vue"
import changePwd from "./changePwd.vue"
import { STORAGE_PRELOGIN_PAGE, gzh_Qrcode_key } from '@/service/constant'
import ChangeAvatarDialog from './ChangeAvatarDialog.vue'
import bind from "./bind.vue"
import REQUEST from '@/api/http'
import { getToken, setToken } from '@/service/login'
import myPageDialog from '@/views/organization/my/myPageDialog.vue'

export default {
  components: { LayOut, Login, Reg, Notice, vconsoleSwitch, promotionDialog, afterQrcode, changePwd, ChangeAvatarDialog, bind, myPageDialog },
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.ruleForm.password !== value) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    const equalToPasswordOld = (rule, value, callback) => {
      if (window.localStorage.getItem('password') !== value) {
        callback(new Error('原密码错误'))
      } else {
        callback()
      }
    }
    // alert(window.localStorage.getItem('token'))
    return {
      vipInfoModel: false,
      drawer: true,
      action: HTTP_URI.CHANGEAVATAR,
      // headers: {
      //   token: this.token
      // },
      notices: '',
      direction: 'ltr',
      pwd: false,
      type: 'password',
      type1: 'password',
      type2: 'password',
      lishi: [],
      avaterModal: false,
      isActive: 1,
      size: 300,
      loadingImg: false,
      phone: false,
      showPage: true,
      show: true,
      loginNun: 0,
      navs: [],
      reg_type: 1,
      userInfos: {},
      navName: '',
      kitList: [],
      isOpenMj: 0,
      isOpenBing: 0,
      FlagStudio: 0,
      isOpenSd: 0,
      showMenu: false,
      ruleForm: {
        oldpwd: '',
        password: '',
        passwords: ''
      },
      rules: {
        oldpwd: [
          { required: true, message: '请输入旧密码!', trigger: 'blur' },
          { required: true, validator: equalToPasswordOld, trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入新密码!', trigger: 'blur' }],
        passwords: [
          { required: true, message: '请输入确认密码!', trigger: 'blur' },
          { required: true, validator: equalToPassword, trigger: 'blur' }
        ]
      },
      vipInfo: {},
      promotionDialogVisible:false,
      afterQrcodeVisible:false,
      openId:null,
      changePwdVisible:false,

      modifyPwdSHowloginVisible:false,//修改密码成功后是否要显示登录或注册弹窗

      ChangeAvatarDialogVisible:false,
      showType: false,
      formPath: '',
      orgInfo: null,
      isXmrc: false,
      terminal: '',
      inAppPage: false,
    }
  },
  created() {
    
  },
  computed: {
    vipDialogwidth(){
        if(isMobile()){
            return "80%"
        }
        return "400px"
    },
    ...mapState(['userId','orgId','orgTag','scoket', 'openMenu', 'total', 'userInfo', 'avatar', 'notice', 'gpt3Total', 'gpt4Total', 'loginVisible', 'haveUserInfo', 'nickName', 'afterQrcodeShow', 'topInviteBtnShow', 'topVipBtnShow']),
    headers(){
      return {
        token: getToken()
      }
    },

    anonymous(){
        return isAnonymous()
    }
  },
  watch: {
    $route: {
        handler(to, from) {
            this.loadOrgInfo()
            if(to.path.indexOf("/app") == 0){
                if(isMobile()){
                    this.inAppPage = true
                }else{
                    this.inAppPage = false
                }
            }else{
                this.inAppPage = false
            }
        },
        deep: true, // 深度观察监听 设置为 true
        immediate: true // 第一次初始化渲染就可以监听到
    },
    userInfo(val) {
      if (val.name) {
        this.userInfos = val
      } else {
        this.userInfos = JSON.parse(window.localStorage.getItem('userInfo'))
      }
    },
    openMenu(val) {
      this.showMenu = val
      this.drawer = !val
    },
    notice(val) {
      this.notices = val
    },
    // orgId(val) {
    //     this.loadOrgInfo()
    // },
    // orgTag(val) {},
  },
  async mounted() {
    this.terminal = localStorage.getItem('terminal')
    
    let baseUrl = localStorage.getItem('baseRouter')
    if(baseUrl){
        this.isXmrc = baseUrl.includes("ai.xmgxrc.com")
    }else{
        this.isXmrc = location.href.includes("ai.xmgxrc.com")
    }
    localStorage.setItem('isXmrc', this.isXmrc)

    this.phone = JSON.parse(window.localStorage.getItem('phone'))

    this.notices = this.notice
    this.isActive = this.$route.meta.index

    window.addEventListener("resize", ()=>{
        var h = (window.innerHeight)+"px";
        if(document.getElementsByClassName("mains")[0]){
            document.getElementsByClassName("mains")[0].style.height = (window.innerHeight) + "px"
        }
        if(document.getElementsByClassName("mian")[0]){
            document.getElementsByClassName("mian")[0].style.height = (window.innerHeight - 50) + "px"
        }
    });

    window.addEventListener("DOMContentLoaded", ()=>{
        var h = (window.innerHeight)+"px";
        if(document.getElementsByClassName("mains")[0]){
            document.getElementsByClassName("mains")[0].style.height = (window.innerHeight) + "px"
        }
        if(document.getElementsByClassName("mian")[0]){
            document.getElementsByClassName("mian")[0].style.height = (window.innerHeight - 50) + "px"
        }
    });
    var h = (window.innerHeight)+"px";
    
    if(document.getElementsByClassName("mains")[0]){
        document.getElementsByClassName("mains")[0].style.height = (window.innerHeight) + "px"
    }
    if(document.getElementsByClassName("mian")[0]){
        document.getElementsByClassName("mian")[0].style.height = (window.innerHeight - 50) + "px"
    }
    // this.promotionDialogVisible = true

    this.showVIpPic()
    
    this.showAfterCodeDialog()

  },
  methods: {
        showChange(){
            if(this.phone){
                if(this.$route.name=='app'){
                    if(this.openMenu){
                        return true
                    }else{
                        return false
                    }  
                }else{
                    return true
                }
            }else{
                return true
            }
        },
      async loadOrgInfo(){
        // if (!getToken()){
        //     this.formPath = '/home'
        //     this.showType = false
        //     return
        // }
        const orgId = this.$store.state.orgId
        if(this.terminal=='app'){
            this.formPath = '/appHome'
            this.showType = false
        }else if(orgId&&orgId!=0){
            if(this.$route.name=='OrgannizationMain'||this.$route.name=='home'){
                this.formPath = '/home'
                this.showType = false
            }else{
                let orgInfo = this.$store.state.orgInfo
                if(orgInfo&&orgInfo.id!=orgId){
                    var res = await REQUEST.GET_QUERY('getOrganizationById', {id: orgId, orgId: orgId})
                    this.orgInfo = res.data
                }else{
                    this.orgInfo = orgInfo
                }
                if(this.orgInfo&&this.orgInfo.tag){
                    this.formPath = "/starship/"+this.orgInfo.tag
                    this.showType = true
                }else{
                    this.formPath = '/home'
                    this.showType = false
                }
            }
        }else{
            this.formPath = '/home'
            this.showType = false
        }
      },
      async showVIpPic(){
          if (!getToken()){
              return
          }
          var res = await this.$https.get('VIP_INFO', {})
          if (res.status == 200) {
            var hasShow = hasShowVipPic()
            // console.log("是否显示过VIP弹窗", hasShow)

            if(res.data && res.data.name == '免费版'){
            //   this.promotionDialogVisible = !hasShow
              setTodayHasShowVipPic()
            }
          }
          
      },
      getVipInfo() {
          if (!getToken()){
              return
          }
          this.$https.get('VIP_INFO', {}).then(res => {
              if (res.status == 200) {
                // console.log("vipinfo返回", res.data)
                this.vipInfo = res.data;

                if(this.vipInfo.expiryTime){
                  this.vipInfo.expiryTime = this.vipInfo.expiryTime.split(" ")[0]

                }

              }
          })
      },
      async getHome(){
        // var res = await this.$https('HOME_V2', {sendType: 1})

        this.$store.dispatch('loadHomeData')
        // this.$store.commit('SET_TOTAL', res.data.remainingTimes)
        // this.$store.commit('SET_GPT3_TOTAL', res.data.remainingTimesGpt3)
        // this.$store.commit('SET_GPT4_TOTAL', res.data.remainingTimesGpt4)
        // this.$store.commit('SET_AVATAR', res.data.avatar)
      },
      goToIndex() {
        routerPush("/")
      },
      handleClose() {
        this.vipInfoModel = false;

      },
    // 获取菜单
    getMenu() {
      const menus = [
        {
          id: 1,
          icon: 'el-icon-chat-line-round',
          name: 'AI对话',
          show: true,
          path: '/'
        },
        {
          id: 2,
          icon: 'el-icon-picture-outline',
          name: 'AI画图',
          show: true,
          path: '/picture'
        },
        {
          id: 3,
          icon: 'el-icon-s-help',
          name: 'Bing',
          show: false,
          path: '/bing'
        },
        {
          id: 4,
          icon: 'el-icon-picture-outline-round',
          name: 'Stable Diffusion',
          show: false,
          path: '/sdpage'
        },
        {
          id: 5,
          icon: 'el-icon-postcard',
          name: 'Flagstudio画图',
          show: false,
          path: '/flagstudio'
        },
        {
          id: 6,
          icon: 'el-icon-postcard',
          name: 'Midjourney',
          show: false,
          path: '/midjourney'
        },
        {
          id: 7,
          icon: 'el-icon-money',
          name: '充值',
          show: true,
          path: '/product'
        }
      ]
      menus.map(item => {
        if (this.isOpenSd > 0 && item.path == '/sdpage') {
          item.show = true
        }
        // if (this.isOpenMj > 0 && item.path == '/midjourney') {
        //   item.show = true
        // }
        if (this.FlagStudio > 0 && item.path == '/flagstudio') {
          item.show = true
        }
        if (this.isOpenBing > 0 && item.path == '/bing') {
          item.show = true
        }
      })
      this.navs = menus
      this.lishi = [
        {
          id: 1,
          icon: 'el-icon-chat-line-round',
          name: 'AI对话',
          show: true,
          path: '/'
        },
        {
          id: 2,
          icon: 'el-icon-picture-outline',
          name: 'AI画图',
          show: true,
          path: '/picture'
        }
      ]
    },
    chanePage(data) {
      this.showPage = this.showPage ? false : true
    },
    // 成功登录返回
    successLogin() {

        this.afterQrcodeVisible = false

        this.$bus.$emit('afterQrcodeClearInterval')

        console.log("------- 成功登录返回 ---------");

      this.$store.commit('SET_LOGIN_VISIBLE', false)

      //如果是因为未登录而被路由守卫拦截后跳到这个页面，就会有这个值
      var wantToPath = localStorage.getItem(STORAGE_PRELOGIN_PAGE)
    //   console.log("]]]]] wantToPath", wantToPath);
      if(wantToPath){
          this.$nextTick(() => {
              this.$refs.child.loginFinish()
          })
          return
      }

      routerPush("/")

      this.getNavs()

      this.$nextTick(() => {
          this.$refs.child.loginFinish()
      })

      this.showVIpPic()

    },
    getNavs() {
      this.$https('NAVSHOWORHIDE', {}).then(res => {
        if (res.status == 200) {
          this.isOpenBing = res.data.isOpenBing
          this.FlagStudio = res.data.FlagStudio
          this.isOpenSd = res.data.isOpenSd
          this.isOpenMj = res.data.isOpenMj
          this.isActive = 1
          if (this.phone) {
            // this.$store.commit('SET_OPEN_MENU', true)
            // this.getUserInfo()
            this.getMenu()
            setTimeout(() => {
              this.$store.commit('SET_OPEN_MENU', false)
            }, 500)
          } else {
            setTimeout(() => {
              this.getMenu()
              // this.getUserInfo()
            }, 500)
          }
        }
      })
    },
    // 下拉设置
    async handleCommand(command) {
      switch (command) {
        case 'home':
            this.goHomeBtnClick()
            break
        case 'changePwd':
          this.modifyPwdSHowloginVisible = false// 修改成功后不显示登录或注册弹窗
          this.changePwdVisible = true
          break
        case 'changeTx':
        //   this.avaterModal = true
            this.ChangeAvatarDialogVisible = true
          break
        case 'signOut':
          this.$refs.layout.open()
          break
        case 'payPage':
            // this.$router.push('/product')
            window.open("/product", '_blank')
            break;
        case 'vipInfo':
            // this.vipInfo = JSON.parse(window.localStorage.getItem('vipInfo'))
            this.getVipInfo()
            this.getHome()
            this.vipInfoModel = true
            break;
        case "invite":
          
            this.inviteBtnClick()
            break;
        case "organization":
            this.organizationBtnClick()
            break;

      }
    },
    // 获取用户信息
    getUserInfo() {
      this.$https('HOME_V2', {
        sendType: 1
      }).then(res => {
        // console.log("mainUser getUserInfo", res)
        if (res.status == 200) {

          // this.$store.commit('SET_TOTAL', res.data.remainingTimes)
          // this.$store.commit('SET_GPT3_TOTAL', res.data.remainingTimesGpt3)
          // this.$store.commit('SET_GPT4_TOTAL', res.data.remainingTimesGpt4)

          // this.$store.commit('SET_NOTICE', res.data.announcement)
          // if (res.data.logList && res.data.logList.length > 0) {
          //   console.log("mainUser res.data.logList[0]", JSON.parse(res.data.logList[0].content))
          //   this.$store.commit('SET_MESSAGES', JSON.parse(res.data.logList[0].content))
          // }
          
        } else {
          return false
        }
      })
    },
    // 适配手机开关
    toggle() {
      this.showMenu = !this.showMenu
      this.$store.commit('SET_OPENMENU', this.showMenu)
    },
    // 临时用户
    getauth() {
      this.$https('LINSHILOGIN', {
        browserFingerprint: window.localStorage.getItem('murmur'),
        authToken: md5(window.localStorage.getItem('murmur') + 'AIGo.work')
      }).then(res => {
        if (res.status == 200) {
            this.$store.commit('SET_USERINFO', res.data)
            window.localStorage.setItem('loginNum', 2)
            // this.userInfo = res.data
            // window.localStorage.setItem('token', res.data.token)
            this.$store.commit('SET_isXmrc', res.data.isXmrc)
            setToken(res.data.token)
            window.localStorage.setItem('userInfo', JSON.stringify(res.data))
            this.$store.commit('SET_LOGIN_VISIBLE', false)

            window.localStorage.setItem('userId', res.data.userId)
            routerPush('/')
            this.getUserInfo()
            this.getNavs()
        }
      })
    },
    // 路由调整
    gotoPage(item) {
      this.isActive = item.id
      this.$store.commit('SET_OPEN_MENU', false)
      routerPush(item.path)
    },
    // 打开登录弹框
    openModal() {
        let isXmrc = JSON.parse(JSON.stringify(this.$store.state.isXmrc))
        this.$store.commit('SET_TOKEN', null)
        setToken(null)
        this.$store.commit('SET_HAVE_USER_INF', false)
        window.localStorage.clear()
        // console.log(this.$router.currentRoute);
        localStorage.setItem(STORAGE_PRELOGIN_PAGE, location.href)
        this.$store.commit('SET_isXmrc', isXmrc)
        this.$router.push("/login")
        // this.$store.commit('SET_LOGIN_VISIBLE', true)

        // if (window.localStorage.getItem('loginNum') && window.localStorage.getItem('loginNum') !== 1) {
        //   this.loginNun = window.localStorage.getItem('loginNum')
        // } else {
        //   this.loginNun = 1
        //   window.localStorage.setItem('loginNum', 1)
        // }
    },
    // 去支付
    payPage() {
      this.$store.commit('SET_OPEN', false)
      window.localStorage.setItem('navs_open', 6)
      routerPush('/product')
    },
    // 修改密码
    changePwd() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$https('CHANGEPWD', {
            password: this.ruleForm.password
          }).then(res => {
            if (res.status == 200) {
              this.$message.success('密码修改成功，正在退出登录！')

              setTimeout(() => {
                window.localStorage.clear()
                    this.$store.commit('SET_TOKEN', null)
                    this.$store.commit('SET_orgId', '')
                    this.$store.commit('SET_nickName', '')
                    this.$store.commit('SET_userId', '')
                    this.$store.commit('SET_OPEN', false)
                    this.$store.commit('SET_TOTAL', 0)
                    this.$store.commit('SET_USERINFO', {})
                    this.$store.commit('SET_AVATAR', '')
                    this.$store.commit('SET_NOTICE', '')
                    this.$store.commit('SET_MESSAGES', [])
                    this.$store.commit('SET_LS_TOTALS', 0)
                    this.$store.commit('SET_SCOKET', {})
                    this.$store.commit('SET_OPEN_MENU', false)
                    gotoHref('/login')
                    window.location.reload()
              }, 1000)

              this.pwd = false
            }
          })
        }
      })
    },
    // 关闭修改密码
    cancel() {
      this.$refs.ruleForm.resetFields()
      this.ruleForm = {
        oldpwd: '',
        password: '',
        passwords: ''
      }
      this.pwd = false
    },
    // 上传头像前校验
    handleBefore(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
      const isLt2M = file.size / 1024 / 1024 < 3

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG,PNG或者GIF格式')
      } else if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 3MB!')
      } else {
        this.loadingImg = true
      }
    },
    // 上传头像成功返回
    handleSuccess(res) {
      this.loadingImg = true

      if (res.status == 200) {
        this.$message.success('上传头像成功！')

        this.loadingImg = false
        this.avaterModal = false
        setTimeout(() => {
          // this.$router.go(0)
          // window.location.reload()
          // this.$router.push('/white')
        }, 500)
      } else {
        this.$message.success(res.message)
        this.loadingImg = false
      }
    },
    loginCLoseClick(){
        this.$bus.$emit('afterQrcodeClearInterval')

        localStorage.removeItem(gzh_Qrcode_key)
        this.$store.commit('SET_LOGIN_VISIBLE', false)

    },
    goHomeBtnClick(){
        if(this.isXmrc){
            // 跳转到厦门人才星舰主页
            routerPush('/starship/198909')
            return
        }else if(this.terminal=='app'){
            routerPush("/appHome")
        }else{
            routerPush("/home")
            return
        }
    },
    goStarShip(){
        routerPush(this.formPath)
    },
    buyBtnClick(){
      var userCode = getQueryString("userCode")
      if(userCode){
        window.open("/product?userCode=" + userCode, '_blank')

      }else{
        window.open("/product", '_blank')
      }

    },
    inviteBtnClick(){
      routerPush("/inviteIndex")
    },

    organizationBtnClick(){
        routerPush("/OrgannizationMain")
    },

    async showAfterCodeDialog(){

      if (getToken()) {
        //已登录
      }else{
        //未登录
        // this.openId = getQueryString("o")
        // var wx = isWx()
        // if(this.openId && wx){
        //   //未登录且地址里有openId，就显示注册和绑定弹窗
        //   this.afterQrcodeVisible = true
          
        // }

            var wx = isWx()
            if(wx){
                var gzhQrcode = localStorage.getItem(gzh_Qrcode_key)
                if(gzhQrcode){
                    console.log("======= 本地有保存公众号二维码 =====");
                    var ticket = getQueryStrByUrl(gzhQrcode, "ticket")
                    var res = await REQUEST.POST_QUERY('GET_LOGIN_BY_WX2', {ticket}, false)
                    console.log('GET_LOGIN_BY_WX2返回：', res);

                    //res.data不为空表示有结果了，
                    //res.data.userId > 0 表示已经已关注并绑定手机号了
                    //res.data.userId <= 0 表示未关注或未绑定手机号
        
                    if(res.data){
                        if(res.data.userId <= 0){
                            this.afterQrcodeVisible = true
                            this.openId = res.data.fromUserName
                        }
                    }else{
                        localStorage.removeItem(gzh_Qrcode_key)
                    }
                }else{
                    console.log("======= 本地 没有 保存公众号二维码 =====");

                }
            }

      }

    },
    afterQrcodeClose(){
      this.afterQrcodeVisible = false
    },
    afterQrcodeFinish(){
      this.afterQrcodeVisible = false
    },
    forgetPwd(){
      this.$store.commit('SET_LOGIN_VISIBLE', false)
      this.changePwdVisible = true
      this.modifyPwdSHowloginVisible = true
      console.log("修改密码界面关闭后是否显示注册或登录", this.modifyPwdSHowloginVisible)
    },
    modifyClose(){
      if(this.modifyPwdSHowloginVisible){
        this.$store.commit('SET_LOGIN_VISIBLE', true)
        this.showPage = true
      }
    },

    clickMenuBtn(){
        if(this.openMenu){
          this.$store.commit('SET_OPENMENU', false)
        }else{
          this.$store.commit('SET_OPENMENU', true)
        }

    },
    // 退出游客登录
    logOutAnonymouse(){
        this.$refs.layout.open('退出游客状态？')
    }
  }
}
</script>

<style lang="scss" scoped>
.bodys {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.el-drawer__wrapper {
  z-index: 10 !important;
}
.drawers {
  box-shadow: none !important;
  background: #f1f2f3 !important;
}
.tx_box {
    width: 20px;
    height: 20px;
    border: 1px solid;
    border-radius: 50px;
    /*margin-top: 30px;*/
    cursor: pointer;
    /*margin-right: 10px;*/
    vertical-align: middle;
    object-fit: cover;
}
.nav_box {
  width: 80px;
  height: 100vh;
  display: flex;
  overflow-y: hidden;
  .left {
    /*background: #000000;*/
    background: #202123;
    width: 80px;
    padding-top: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-right: 1px solid #ffffff;
    color: #ffffff;

    .navs_box {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: -30px;
      .navs {
        width: 80px;
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-top: 20px;

        .li.active {
          background: rgba(255, 255, 255, 1);
          color: rebeccapurple;
          .nav_text {
            color: rebeccapurple;
          }
        }
        .li:hover {
          background: rgba(255, 255, 255, 1);
          color: rebeccapurple;
          .nav_text {
            color: rebeccapurple;
          }
        }
        .li {
          cursor: pointer;
          border-radius: 5px;
          height: 60px;
          width: 60px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          .li_div {
            display: flex;
            flex-flow: column;
            .nav_text {
              font-size: 12px;
              line-height: 1;
              margin-top: 5px;
            }
          }
          .li_div.active {
            i {
              color: #000;
            }
            .nav_text {
              color: #000;
            }
          }
        }
      }
      .cishu {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
      }
    }
  }
}
.boxes {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mains.phone {
  width: 100vw;
  left: 0;
}
.mains.phone.openMenu {
  width: calc(100vw);
    height: 100vh!important;
  /*left: 80px;*/
}
.mains {
  // background-color: rgb(236, 91, 255);
  position: absolute;
  z-index: 999;
  top: 0;
  width: calc(100vw);
  /*left: 80px;*/
  height: 100vh;
  overflow: hidden;
  .headers {
    height: 38px;
    padding-right: 20px;
    /*background: #000000;*/
    background: rgb(48, 57, 66);
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    align-items: center;
  }
  .mian {
    height: calc(100vh - 38px) !important;
    overflow: auto;
  }
}
.avaterx {
  display: flex;
  flex-flow: column;
  // justify-content: center;
  align-items: center;
}
.dropsownWrap{
  height: 20px;
}

.dropsownWrapInner{
//   position: relative;
//   top: -0;
//   height: 26px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.topNickname{
    color: #fff;
    padding: 0 2px 0 5px;
}
.vconsoleSwitch{
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
}

.headerLeft{
  display: flex;
  flex-direction: row;
  height: 100%;
  .goHome{
    height: 100%;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    margin-left: 12px;
    display: flex;
    align-items: center;
    .showIconLeft{
        display: flex;
        flex-direction: row;
        align-items: center;
        .orgIcon{
            width: 22px;
            height: 22px;
            display: block;
            border-radius: 30px;
        }
        .orgName{
            font-size: 14px;
            color: rgb(199, 199, 199);
            padding-left: 6px;
        }
    }
  }
  .menuBtn{
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  .inviteBtn{
    height: 50px;
    font-size: 13px;
    line-height: 50px;
    color: #fff;
    cursor: pointer;
    margin-left: 16px;
  }
}
.isLimit{
    max-width: calc(100vw - 200px);
}
.vipDialog{
  // width: 500px;
  .topWrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;

    .myPackage{
      font-size: 15px;
      font-weight: bold;
    }
    .memberType{
      color: #45c084;
      font-size: 15px;
    }
    .time{
      color: #777;
      padding-left: 20px;
      font-size: 13px;

    }
    .buyBtn{
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background-color: #45c084;;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-size: 12px;
    }
  }
  .vipInfoWrap{
    padding: 22px;
    border-radius: 12px;
    background: rgb(242, 243, 245);
    .item{
      padding: 4px 0;
      position: relative;
    }
    img{
      width: 10px;
      height: 11px;
      position: relative;
      top: 2px;
    }
    .infoText{
      padding-left: 8px;
    }
  }
  .useInfo{
    font-size: 15px;
    color: #000;
    font-weight: bold;
    padding: 15px 0 5px;
  }
  .myVipInfoWrap{
      padding: 22px;
      border-radius: 12px;
      background: rgb(242, 243, 245);
      .vipInfoItem{
        padding: 6px 0;
      }
  }
}

.headRight{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.vipDoor{
  width: 100px;
  height: 22px;
  margin-right: 10px;
  cursor: pointer;
  img{
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>